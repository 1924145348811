<template lang="pug">
  div.my-title-box.pl-8
    .my-subtitle.text-uppercase.orange--text.lighten-1.mb-4 {{ eng }}
    .text-h4.font-weight-bold {{ chin }} 
</template>

<script>
export default {
  name: "Title",

  props: {
    chin: {
      type: String,
      default: "",
    },
    eng: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="sass" scoped>
.my-title-box
  position: relative
  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 8px
    height: 100%
    background: lighten(orange, 10%)
    border-radius: 4px

.my-subtitle
  font-size: 1rem
  line-height: 1rem
</style>
