<template lang="pug">
  .py-8.mx-2(:class="{ 'mx-16': $vuetify.breakpoint.mdAndUp }")
    v-row
      v-col(cols="12")
        Title(:chin="$t('App_5')" eng="heart road")
      v-col(cols="12" md="6" v-for="heartRoad in heartRoads" :key="heartRoad.heartRoadSn")
        v-card.d-flex.flex-column(height="100%" @click="goto(heartRoad.permalinkUrl)")
          v-img(:src="heartRoad.fullPicture?heartRoad.fullPicture:'/blog_normal.png'" eager)
          v-card-text.text-subtitle-1.text-no-wrap.text-truncate.d-block {{ heartRoad.message }}
          v-card-subtitle.pt-0
            v-chip.mr-1.px-1(v-for="(messageTag, idx) in heartRoad.messageTags" :key="idx" small outlined label) {{ messageTag.name }}
            .d-flex.align-center.text-caption.grey--text.mt-1
              v-icon(size="14") mdi-calendar-blank-outline
              span.ml-1 {{ heartRoad.createdTime | moment }}
              v-spacer
              v-icon.ml-3(size="14") mdi-thumb-up-outline
              span.ml-1 {{ heartRoad.reactions||0 }}
              v-icon.ml-3(size="18") mdi-share-outline
              span.ml-1 {{ heartRoad.shares||0 }}
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import Title from "@/components/common/Title.vue";

export default {
  name: "HeartRoad",

  components: {
    Title,
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions(["GetHeartRoads"]),
    moment: function () {
      return moment();
    },
    goto(url) {
      window.open(url);
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
  },

  computed: {
    ...mapGetters(["heartRoads"]),
  },

  async mounted() {
    await this.GetHeartRoads();
  },
};
</script>
