<template lang="pug">
  .py-16
    div(:class="{ 'mx-16': $vuetify.breakpoint.mdAndUp, 'px-10': $vuetify.breakpoint.mdAndUp }")
      .my-card.overflow-hidden.d-flex(:class="{ 'flex-column': $vuetify.breakpoint.smAndDown, 'rounded-lg shadow': $vuetify.breakpoint.mdAndUp }")
        div.px-12.pt-10.pb-6(:class="{ 'w-45p': $vuetify.breakpoint.mdAndUp }")
          Title(:chin="$t('KnowUSoon_1')" eng="choose usoon")
          div.my-content.grey--text.text--darken-2.mt-8 {{ $t('KnowUSoon_3') }}
        div(:class="{ 'w-55p': $vuetify.breakpoint.mdAndUp }")
          v-img(src="/ms-1.webp" height="100%" eager)
    div.mt-16.py-16
      .my-card.d-flex(style="position: relative;" :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }")
        div(:class="{ 'w-55p': $vuetify.breakpoint.mdAndUp }")
          v-img(src="/unique-1.webp" height="100%" eager)
        .my-custom-content.shadow(v-if="$vuetify.breakpoint.mdAndUp")
          div.px-12.pt-10.pb-6
            Title(:chin="$t('KnowUSoon_2')" eng="top one medical")
            div.my-content-2.grey--text.text--darken-2.mt-4 {{ $t('KnowUSoon_4') }}
        div.px-12.pt-10.pb-6(v-if="$vuetify.breakpoint.smAndDown")
          Title(:chin="$t('KnowUSoon_2')" eng="top one medical")
          div.my-content-2.grey--text.text--darken-2.mt-4 {{ $t('KnowUSoon_4') }}
</template>

<script>
import Title from "@/components/common/Title.vue";

export default {
  name: "KnowUSoon",

  components: {
    Title,
  },

  data() {
    return {};
  },
};
</script>

<style lang="sass" scoped>
.my-card
  .w-45p
    width: 45%
  .w-55p
    width: 55%
  .w-65p
    width: 65%

.shadow
  box-shadow: 0 0 10px rgba(0,0,0,.2)

.my-custom-content
  position: absolute
  top: 10%
  right: 6%
  z-index: 1
  background: rgba(255,255,255,0.85)
  width: 55%

.my-content
  padding-left: 32px
  white-space: pre-line
  letter-spacing: 0.2rem
  line-height: 2rem

.my-content-2
  padding-left: 32px
  white-space: pre-line
  letter-spacing: 0.1rem
  line-height: 1.5rem
</style>
