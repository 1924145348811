<template lang="pug">
  .py-16
    v-row.mx-2(:class="{ 'mx-16': $vuetify.breakpoint.mdAndUp }")
      v-col(cols="12" md="6")
        v-img(:src="docBase.filePath?baseURL+docBase.filePath:'/blog_normal.png'" eager style="border-radius: 12px" aspect-ratio="1")
        DoctorSchedule.mt-3(v-if="$vuetify.breakpoint.mdAndUp")
      v-col.px-8.py-4(cols="12" md="6")
        DocName(:name="docBase.docName")
        .text-h6.mt-4.my-subtitle {{ $t('OurTeam_1') }}
        div.my-content.grey--text.text--darken-2(v-for="edu in docEdus" :key="edu.docEduSn") {{ edu.education }}
        .text-h6.mt-4.my-subtitle {{ $t('OurTeam_2') }}
        div.my-content.grey--text.text--darken-2(v-for="exp in docExps" :key="exp.docExpSn") {{ exp.experience }}
        .text-h6.mt-4.my-subtitle {{ $t('Doctor_1') }}
        ul 
          li(v-for="skill in docSkills" :key="skill.docSkillSn") 
            div.my-content.grey--text.text--darken-2 {{ skill.skill }}
      v-col(v-if="$vuetify.breakpoint.smAndDown" cols="12")
        DoctorSchedule.mt-3
</template>

<script>
import DocName from "@/components/common/DocName.vue";
import DoctorSchedule from "@/components/DoctorSchedule.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Doctor",

  components: {
    DocName,
    DoctorSchedule,
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions([
      "GetDocBase",
      "GetDocEdus",
      "GetDocExps",
      "GetDocSkills",
      "GetDocShifts",
    ]),
  },

  computed: {
    ...mapGetters(["docBase", "docEdus", "docExps", "docSkills", "docShifts"]),
    uid() {
      return this.$route.params.uid;
    },
    baseURL() {
      return process.env.VUE_APP_BACKEND_BASE_URL;
    },
  },

  watch: {
    uid: {
      async handler(n, o) {
        if (n) {
          const docBaseSn = this.$route.params.uid;
          await this.GetDocBase({ docBaseSn });
          await this.GetDocEdus({ docBaseSn });
          await this.GetDocExps({ docBaseSn });
          await this.GetDocSkills({ docBaseSn });
          await this.GetDocShifts({ docBaseSn });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.my-subtitle
  letter-spacing: 0.2rem !important

.my-content
  letter-spacing: 0.1rem

td
  text-align: center
  white-space: nowrap
  &.t-double
    border-top: 2px double rgba(0, 0, 0, 0.12) !important
  &.b-double
    border-bottom: 2px double rgba(0, 0, 0, 0.12) !important
  &.t-dotted
    border-top: 1px dotted rgba(0, 0, 0, 0.12) !important
  &.b-dotted
    border-bottom: 1px dotted rgba(0, 0, 0, 0.12) !important
</style>
