import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";
import KnowUSoon from "../views/KnowUSoon.vue";
import HowToReserve from "../views/HowToReserve.vue";
import Reserve from "../views/Reserve.vue";
import Service from "../views/Service.vue";
import ReserveCheck from "../views/ReserveCheck.vue";
import Doctor from "../views/Doctor.vue";
import News from "../views/News.vue";
import NewsInfo from "../views/NewsInfo.vue";
import HeartRoad from "../views/HeartRoad.vue";
import ContactUs from "../views/ContactUs.vue";
import BackStage from "../views/BackStage.vue";
import DocGroup from "../views/DocGroup.vue";
import Banners from "../views/Banners.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/KnowUSoon",
    name: "KnowUSoon",
    component: KnowUSoon,
  },
  {
    path: "/HowToReserve",
    name: "HowToReserve",
    component: HowToReserve,
  },
  {
    path: "/Reserve",
    name: "Reserve",
    component: Reserve,
  },
  {
    path: "/ReserveCheck",
    name: "ReserveCheck",
    component: ReserveCheck,
  },
  {
    path: "/Service",
    name: "Service",
    component: Service,
  },
  {
    path: "/Doctor/:uid",
    name: "Doctor",
    component: Doctor,
  },
  {
    path: "/News",
    name: "News",
    component: News,
  },
  {
    path: "/News/:uid",
    name: "NewsInfo",
    component: NewsInfo,
  },
  {
    path: "/HeartRoad",
    name: "HeartRoad",
    component: HeartRoad,
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/BackStage",
    name: "BackStage",
    component: BackStage,
    children: [
      {
        path: "DocGroup",
        name: "DocGroup",
        component: DocGroup,
      },
      {
        path: "Banners",
        name: "Banners",
        component: Banners,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
