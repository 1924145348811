<template lang="pug">
  .py-8.mx-2(:class="{ 'mx-16': $vuetify.breakpoint.mdAndUp }")
    v-row
      v-col(cols="12" md="10")
        Title(:chin="$t('App_4')" eng="news")
      v-col.d-flex.align-end(cols="12" md="2")
        v-select(v-model="selectOrder" :items="orders" :placeholder="$t('News_1')" dense outlined hide-details color="grey lighten-4" :menu-props="{ offsetY: true }")
      v-col(v-if="$vuetify.breakpoint.smAndDown" cols="12")
        v-card.pa-3(flat outlined color="grey lighten-4")
          .text-h6.px-2 {{ $t('News_2') }}
          v-divider.mt-2.mb-4
          v-list.pa-0(nav dense color="transparent")
            v-list-item-group(v-model="selectTag" color="primary")
              v-list-item(v-for="tag in tags" :key="tag.text" :value="tag.text")
                v-list-item-content
                  .d-flex.justify-space-between
                    span {{ tag.text }}
                    span {{ tag.count }}
          v-text-field.mt-3(v-model="selectKeyWord" :placeholder="$t('News_3')" append-icon="mdi-magnify" outlined hide-details)
    v-row.mt-2
      v-col(cols="12" md="10")
        v-row
          v-col(cols="12" md="4" v-for="article in articlesPerPage" :key="article.uid")
            v-card.d-flex.flex-column(:to="{ name: 'NewsInfo', params: { uid: article.uid } }" height="100%")
              v-img(:src="article.img_url" eager)
              v-card-title.text-subtitle-1 {{ article.title }}
              v-card-subtitle.mt-1
                .d-flex.justify-space-between
                  .d-flex.align-center.text-caption.grey--text
                    v-icon(size="14") mdi-calendar-blank-outline
                    span.ml-1 {{ article.post_date }}
                    v-icon.ml-3(size="14") mdi-eye
                    span.ml-1 {{ article.read_num }}{{ $t('News_4') }}
                  v-chip(color="primary" outlined pill small) {{ $t('News_5') }}
          v-col(cols="12").d-flex.justify-center                  
            v-pagination(v-model="page" :length="pages" :total-visible="6")
      v-col(v-if="$vuetify.breakpoint.mdAndUp" cols="2")
        v-card.pa-3(flat outlined color="grey lighten-4")
          .text-h6.px-2 {{ $t('News_2') }}
          v-divider.mt-2.mb-4
          v-list.pa-0(nav dense color="transparent")
            v-list-item-group(v-model="selectTag" color="primary")
              v-list-item(v-for="tag in tags" :key="tag.text" :value="tag.text")
                v-list-item-content
                  .d-flex.justify-space-between
                    span {{ tag.text }}
                    span {{ tag.count }}
          v-text-field.mt-3(v-model="selectKeyWord" :placeholder="$t('News_3')" append-icon="mdi-magnify" outlined hide-details)
</template>

<script>
import Title from "@/components/common/Title.vue";
import articles from "../../public/articles.json";

export default {
  name: "News",

  components: {
    Title,
  },

  data() {
    return {
      articles,

      selectOrder: null,
      selectTag: null,
      selectKeyWord: null,
      perPage: 9,
      page: 1,
    };
  },

  computed: {
    tags() {
      let allTags = [];
      this.articles.forEach((article) => {
        allTags = [...allTags, ...article.tags];
      });
      allTags = [...new Set(allTags)];

      const all = [{ text: "所有文章", count: this.articles.length }];
      return [
        ...all,
        ...allTags.map((tag) => ({
          text: tag,
          count: this.articles.filter((article) => article.tags.includes(tag))
            .length,
        })),
      ];
    },
    pages() {
      let articles = this.articles;
      if (this.selectTag && this.selectTag !== "所有文章") {
        articles = articles.filter((article) =>
          article.tags.includes(this.selectTag)
        );
      }
      if (this.selectKeyWord) {
        articles = articles.filter((article) =>
          article.title.includes(this.selectKeyWord)
        );
      }
      return Math.ceil(articles.length / this.perPage);
    },
    articlesPerPage() {
      let articles = this.articles;
      if (this.selectTag && this.selectTag !== "所有文章") {
        articles = articles.filter((article) =>
          article.tags.includes(this.selectTag)
        );
      }
      if (this.selectKeyWord) {
        articles = articles.filter((article) =>
          article.title.includes(this.selectKeyWord)
        );
      }
      switch (this.selectOrder) {
        case 0:
          articles = articles.sort((a, b) => {
            if (a.post_date < b.post_date) {
              return 1;
            }
            if (a.post_date > b.post_date) {
              return -1;
            }
            return 0;
          });
          break;
        case 1:
          articles = articles.sort((a, b) => {
            if (a.read_num < b.read_num) {
              return 1;
            }
            if (a.read_num > b.read_num) {
              return -1;
            }
            return 0;
          });
          break;
      }
      return articles.filter(
        (article, idx) => Math.ceil((idx + 1) / this.perPage) === this.page
      );
    },
    orders() {
      return [
        { value: 0, text: this.$t("News_6") },
        { value: 1, text: this.$t("News_7") },
      ];
    },
  },
};
</script>
