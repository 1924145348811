<template lang="pug">
  div
    Carousel
    MainTitle
    KnowUSoon
    Service(isIndex)
    OurTeam
    ContactUs
</template>

<script>
import Carousel from "@/components/Carousel.vue";
import MainTitle from "@/components/MainTitle.vue";
import KnowUSoon from "@/views/KnowUSoon.vue";
import Service from "@/views/Service.vue";
import OurTeam from "@/components/OurTeam.vue";
import ContactUs from "@/views/ContactUs.vue";

export default {
  name: "Index",

  components: {
    Carousel,
    MainTitle,
    KnowUSoon,
    Service,
    OurTeam,
    ContactUs,
  },
};
</script>
