<template lang="pug">
  div
    .d-flex.align-end(:class="{ 'mt-2': $vuetify.breakpoint.smAndDown }")
      .text-h6 {{ $t('ReserveInfo_1') }}
      .text-h4.orange--text.text--lighten-1.font-weight-bold.ml-3 02-2517-6696
    .text-subtitle-1 {{ $t('ReserveInfo_2') }}
</template>
<script>
export default {
  name: "ReserveInfo",
};
</script>
