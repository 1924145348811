<template lang="pug">
  div
    OutPatientTime
    TrafficInfomation
</template>

<script>
import OutPatientTime from "@/components/OutPatientTime.vue";
import TrafficInfomation from "@/components/TrafficInfomation.vue";

export default {
  name: "ContactUs",

  components: {
    OutPatientTime,
    TrafficInfomation,
  },

  data() {
    return {};
  },
};
</script>
