<template lang="pug">
  div
    v-row(v-if="!pass")
      v-col(cols="4" offset="4")
        v-card.mt-2(color="grey lighten-5")
          form(@submit.prevent="verify" class="d-flex flex-column px-6 pt-8 pb-6")
            v-text-field(v-model="form.account" label="帳號" required outlined dense hide-details)
            v-text-field(v-model="form.password" label="密碼" required outlined dense hide-details type="password" class="mt-4")
            v-btn(type="submit" class="mt-4" text color="blue") 登入
    template(v-else)
      v-divider
      v-row(no-gutters)
        v-col(cols="2")
          v-list(dense nav)
            v-list-item(v-for="route in routes" :key="route.title" :to="route.path" link active-class="blue lighten-5 blue--text")
              v-list-item-icon
                v-icon {{ route.icon }}
              v-list-item-content
                v-list-item-title {{ route.title }}
        v-divider(vertical)
        v-col(cols="10")
          v-container
            router-view
      v-divider
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "BackStage",

  components: {},

  data() {
    return {
      form: {
        account: null,
        password: null,
      },
      routes: [
        {
          title: "醫療團隊",
          icon: "mdi-account-group-outline",
          path: "DocGroup",
        },
        {
          title: "輪播圖",
          icon: "mdi-image-multiple-outline",
          path: "Banners",
        },
      ],
    };
  },

  methods: {
    ...mapActions(["VerifyAuth"]),
    async verify() {
      await this.VerifyAuth(this.form);
      if (this.pass) {
        this.$swal.fire({
          title: "登入成功",
          icon: "success",
          confirmButtonText: "關閉",
        });
        this.$router.push({ name: "DocGroup" });
      } else
        this.$swal.fire({
          title: "無法登入!",
          text: "帳號或密碼有誤",
          icon: "error",
          confirmButtonText: "關閉",
        });
    },
  },

  computed: {
    ...mapState(["pass"]),
  },
};
</script>

<style lang="sass" scoped></style>
