<template lang="pug">
  v-simple-table.transparent
    template(v-slot:default)
      thead
        tr
          th 
          th.text-center(v-for="week in weeks" :key="week.value") {{ week.label }}
      tbody
        tr(v-for="shift in shifts" :key="shift.value")
          td.text-center {{ shift.label }}
          td(v-for="week in weeks" :key="`td-${shift.value}-${week.value}`")
            .d-flex.justify-center.align-center
              v-img(:src="getShiftType({week: week.value, shift: shift.value})" eager max-width="16")
        tr
          td(colspan="7")
            .d-flex.justify-end.align-center
              .d-inline-block.mr-4(v-for="shiftType in shiftTypes" :key="shiftType.value")
                .d-flex.justify-center.align-center
                  v-img(:src="shiftType.memo" eager max-width="16")
                  span.grey--text.text--darken-1.ml-1 {{ shiftType.label }}
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "DoctorSchedule",

  data() {
    return {};
  },

  methods: {
    ...mapActions(["GetWeeks", "GetShifts", "GetShiftTypes"]),
    getShiftType({ week, shift }) {
      const docShifts = _.cloneDeep(this.docShifts);
      const docShift = docShifts.find(
        (docShift) => docShift.week === week && docShift.shift === shift
      );
      if (!docShift) return null;
      const shiftType = this.shiftTypes.find(
        (shiftType) => shiftType.value === docShift.shiftType
      );
      return shiftType.memo;
    },
  },

  computed: {
    ...mapState(["weeks", "shifts", "shiftTypes"]),
    ...mapGetters(["docShifts"]),
  },

  async mounted() {
    await this.GetWeeks();
    await this.GetShifts();
    await this.GetShiftTypes();
  },
};
</script>
