<template lang="pug">
  v-card.pa-4.mt-3(outlined)
    .d-flex.justify-space-between.align-center
      v-card-title.pa-0.pl-2 {{ title }}
      v-dialog(v-model="createForm" width="480")
        template(v-slot:activator="{ on, attrs }")
          v-btn(v-bind="attrs" v-on="on" text color="blue") 新增
        v-card
          v-card-title.text-h6 新增{{ title }}
          v-form(ref="CreateForm" @submit.prevent)
            v-text-field.px-6.mt-2(v-model="text" :rules="rules" :counter="maxlength" :maxlength="maxlength" :label="`${title}名稱`" required outlined)
            v-card-actions.px-6
              v-spacer
              v-btn(text color="blue" @click="create") 送出
    v-simple-table.mt-0
      template(v-slot:default)
        thead
          tr
            th.text-left {{ title }}名稱
            th.text-center 操作
        tbody
          tr(v-for="(item,index) in items" :key="index")
            td {{ item.text }}
            td.text-center 
              v-btn(text color="warning" @click="()=>{ updateForm=true; selected=item; text=item.text }") 編輯
              v-btn(text color="error" @click="deleteItem(item)") 刪除
    v-dialog(v-model="updateForm" width="480")
      v-card
        v-card-title.text-h6 編輯{{ title }}
        v-form(ref="UpdateForm" @submit.prevent)
          v-text-field.px-6.mt-2(v-model="text" :rules="rules" :counter="maxlength" :maxlength="maxlength" :label="`${title}名稱`" required outlined)
          v-card-actions.px-6
            v-spacer
            v-btn(text color="blue" @click="update") 送出
</template>
<script>
export default {
  name: "DocInfoCard",

  props: ["items", "title", "maxlength"],

  data() {
    return {
      rules: [(v) => !!v || `${this.title}必填`],
      createForm: false,
      updateForm: false,
      text: null,
      selected: null,
    };
  },

  methods: {
    create() {
      if (this.$refs.CreateForm.validate()) {
        this.$emit("create", this.text);
        this.$refs.CreateForm.reset();
        this.createForm = false;
      }
    },
    update() {
      if (this.$refs.UpdateForm.validate()) {
        this.$emit("update", this.selected, this.text);
        this.$refs.UpdateForm.reset();
        this.updateForm = false;
      }
    },
    async deleteItem(item) {
      const confirm = await this.$swal.fire({
        title: `請確認是否刪除 [${item.text}]`,
        text: "刪除後無法復原",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "確認",
        cancelButtonText: "取消",
      });
      if (confirm.isConfirmed) {
        this.$emit("delete", item);
      }
    },
  },

  watch: {
    createForm: function (n, o) {
      if (!n) {
        this.$refs.CreateForm.reset();
      }
    },
    updateForm: function (n, o) {
      if (!n) {
        this.$refs.UpdateForm.reset();
      }
    },
  },
};
</script>
