<template lang="pug">
  v-container
    v-img.rounded(src="/reserve01.png" eager contain)
</template>
<script>
export default {
  name: "HowToReserve",

  data() {
    return {};
  },
};
</script>
