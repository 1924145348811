<template lang="pug">
  div
    .d-flex.justify-space-between.align-center.pl-2.mb-1
      .text-h5 醫療團隊
      v-dialog(v-model="dialog.createDocForm" width="320")
        template(v-slot:activator="{ on, attrs }")
          v-btn(v-bind="attrs" v-on="on" outlined color="blue") 新增
        v-card
          v-card-title.text-h6 新增醫師
          v-form(ref="CreateDocBaseForm" @submit.prevent)
            v-text-field.px-6.mt-2(v-model="form.addDocBase.docName" :rules="[v => !!v || '姓名必填']" :counter="20" :maxlength="20" label="姓名" required outlined)
            v-card-actions.px-6
              v-spacer
              v-btn(text color="blue" @click="createDocBase") 新增
    v-divider
    v-row.mt-0
      v-col(cols="4")
        v-card(outlined)
          v-list(dense nav)
            v-list-item-group(v-model="selectedDoc" active-class="blue lighten-5 blue--text" @change="docOnChange")
              v-list-item(v-for="docBase in docBases" :key="docBase.docBaseSn" link)
                v-list-item-avatar
                  v-img(:src="docBase.filePath?baseURL+docBase.filePath:'/blog_normal.png'")
                    template(v-slot:placeholder)
                      v-row.fill-height.ma-0(align="center" justify="center")
                        v-progress-circular(indeterminate color="primary" size="16" width="2")
                v-list-item-content
                  v-list-item-title {{ docBase.docName }}
      v-col(cols="8")
        template(v-if="selectedDoc!=null")
          v-card.pa-4(outlined)
            v-form(ref="UpdateDocBaseForm" @submit.prevent)
              .d-flex.justify-space-between.align-center
                v-card-title.pa-0.pl-2 
                  span.mr-2 基本資料
                  v-btn(outlined x-small color="error" @click="deleteDocBase") 永久刪除
                v-btn(v-if="isChange.docBase" text color="blue" @click="updateDocBase") 儲存
              v-row.mt-0
                v-col(cols="5")
                  v-hover
                    template(v-slot:default="{ hover }")
                      v-img.rounded(:src="form.docBase.filePath?(form.docBase.filePath.indexOf('base64')>-1?'':baseURL)+form.docBase.filePath:'/blog_normal.png'" aspect-ratio="1")
                        template(v-slot:placeholder)
                          v-row.fill-height.ma-0(align="center" justify="center")
                            v-progress-circular(indeterminate color="primary" width="4")
                        v-fade-transition
                          v-overlay(v-if="hover" absolute color="#111111")
                            .d-flex.align-center.mybtn
                              v-file-input.pa-0.ma-0(v-model="form.docBase.file" id="file-input" hide-input @change="docBaseFileOnChange") 
                              label.absolute(for="file-input" style="cursor: pointer;") 上傳照片
                v-col(cols="7")
                  v-text-field(v-model="form.docBase.docName" :rules="[v => !!v || '姓名必填']" :counter="20" :maxlength="20" label="姓名" required outlined @input="isChange.docBase=true")
          DocInfoCard(:items="docEdus.map(edu=>(Object.assign({},edu,{ text: edu.education })))" title="學歷" :maxlength="100" @create="createDocEdu" @update="updateDocEdu" @delete="deleteDocEdu")
          DocInfoCard(:items="docExps.map(exp=>(Object.assign({},exp,{ text: exp.experience })))" title="經歷" :maxlength="100" @create="createDocExp" @update="updateDocExp" @delete="deleteDocExp")
          DocInfoCard(:items="docSkills.map(skill=>(Object.assign({},skill,{ text: skill.skill })))" title="專長" :maxlength="100" @create="createDocSkill" @update="updateDocSkill" @delete="deleteDocSkill")
          v-card.pa-4.mt-3(outlined)
            .d-flex.justify-space-between.align-center
              v-card-title.pa-0.pl-2 排班
              v-spacer
              v-select(v-model="form.docShift.shiftType" :items="shiftTypes" label="班別" item-text="label" item-value="value" :menu-props="{ offsetY: true }" outlined dense hide-details style="width: 64px;")
                template(v-slot:item="{ active, item, attrs, on }")
                  v-list-item(v-on="on" v-bind="attrs" #default="{ active }")
                    v-list-item-content
                      v-list-item-title.d-flex
                        v-img(:src="item.memo" eager max-width="16")
                        span.ml-3 {{ item.label }}
            v-simple-table.mt-0
              template(v-slot:default)
                thead
                  tr
                    th 
                    th 
                    th.text-center(v-for="week in weeks" :key="week.value") {{ week.label }}
                tbody
                  template(v-for="shift in shifts")
                    tr.mytr(v-for="(room, ridx) in rooms" :key="`${shift.value}-${room.value}`")
                      td.text-center(:rowspan="rooms.length" :style="ridx===0?'':'display: none;'") {{ shift.label }}
                      td.text-center {{ room.label }}
                      td(v-for="week in weeks" :key="`td-${shift.value}-${week.value}`" @click="toggleShift(week.value, shift.value, room.value)" style="cursor: pointer;")
                        .d-flex.justify-center.align-center
                          v-img(:src="getShiftType({week: week.value, shift: shift.value, room: room.value})" eager max-width="16")
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DocInfoCard from "../components/DocInfoCard.vue";

export default {
  name: "DocGroup",

  components: {
    DocInfoCard,
  },

  data() {
    return {
      selectedDoc: null,
      dialog: {
        createDocForm: false,
      },
      form: {
        addDocBase: {
          docName: null,
        },
        docBase: {
          docBaseSn: null,
          docName: null,
          filePath: null,
          fileName: null,
          file: null,
        },
        docShift: {
          week: null,
          shift: null,
          shiftType: null,
          room: null,
        },
      },
      isChange: {
        docBase: false,
      },
      shiftLoading: false,
    };
  },

  methods: {
    ...mapActions([
      "GetWeeks",
      "GetShifts",
      "GetShiftTypes",
      "GetRooms",
      "GetDocBases",
      "CreateDocBase",
      "UpdateDocBase",
      "DeleteDocBase",
      "GetDocEdus",
      "CreateDocEdu",
      "UpdateDocEdu",
      "DeleteDocEdu",
      "GetDocExps",
      "CreateDocExp",
      "UpdateDocExp",
      "DeleteDocExp",
      "GetDocSkills",
      "CreateDocSkill",
      "UpdateDocSkill",
      "DeleteDocSkill",
      "GetDocShifts",
      "CreateDocShift",
      "DeleteDocShift",
    ]),
    reset() {
      this.form = {
        addDocBase: {
          docName: null,
        },
        docBase: {
          docBaseSn: null,
          docName: null,
          filePath: null,
          fileName: null,
          file: null,
        },
        docShift: {
          week: null,
          shift: null,
          shiftType: null,
          room: null,
        },
      };
    },
    async docOnChange() {
      this.reset();
      const docBase = _.cloneDeep(this.docBases[this.selectedDoc]);
      this.form.docBase = docBase;
      const { docBaseSn } = docBase;
      await this.GetDocEdus({ docBaseSn });
      await this.GetDocExps({ docBaseSn });
      await this.GetDocSkills({ docBaseSn });
      await this.GetDocShifts({ docBaseSn });
    },
    docBaseFileOnChange() {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          this.form.docBase.filePath = reader.result;
        },
        false
      );
      reader.readAsDataURL(this.form.docBase.file);
      this.isChange.docBase = true;
    },
    async createDocBase() {
      if (this.$refs.CreateDocBaseForm.validate()) {
        const result = await this.CreateDocBase(this.form.addDocBase);
        if (result) {
          this.$refs.CreateDocBaseForm.reset();
          this.dialog.createDocForm = false;
          this.GetDocBases();
          this.$swal.fire({
            title: "新增成功",
            icon: "success",
            confirmButtonText: "關閉",
          });
        } else {
          this.$swal.fire({
            title: "新增失敗",
            icon: "error",
            confirmButtonText: "關閉",
          });
        }
      }
    },
    async updateDocBase() {
      if (this.$refs.UpdateDocBaseForm.validate()) {
        const { docBaseSn, docName, file } = this.form.docBase;
        const formData = new FormData();
        formData.append("docName", docName);
        formData.append("file", file);
        const result = await this.UpdateDocBase({ docBaseSn, formData });
        if (result) {
          this.GetDocBases();
          this.isChange.docBase = false;
          this.$swal.fire({
            title: "基本資料更新成功",
            icon: "success",
            confirmButtonText: "關閉",
          });
        } else {
          this.$swal.fire({
            title: "基本資料更新失敗",
            icon: "error",
            confirmButtonText: "關閉",
          });
        }
      }
    },
    async deleteDocBase() {
      const confirm = await this.$swal.fire({
        title: `請確認是否刪除 [${this.form.docBase.docName}]`,
        text: "刪除後無法復原",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "確認",
        cancelButtonText: "取消",
      });
      if (confirm.isConfirmed) {
        const result = await this.DeleteDocBase(this.form.docBase);
        if (result) {
          this.selectedDoc = null;
          this.reset();
          this.GetDocBases();
          this.$swal.fire({
            title: "刪除完成",
            icon: "success",
            confirmButtonText: "關閉",
          });
        } else {
          this.$swal.fire({
            title: "刪除失敗",
            icon: "error",
            confirmButtonText: "關閉",
          });
        }
      }
    },
    async createDocEdu(education) {
      const { docBaseSn } = this.form.docBase;
      const result = await this.CreateDocEdu(
        Object.assign({}, { docBaseSn, education })
      );
      if (result) {
        this.GetDocEdus({ docBaseSn });
        this.$swal.fire({
          title: "新增學歷成功",
          icon: "success",
          confirmButtonText: "關閉",
        });
      } else {
        this.$swal.fire({
          title: "新增失敗",
          icon: "error",
          confirmButtonText: "關閉",
        });
      }
    },
    async updateDocEdu(edu, education) {
      const result = await this.UpdateDocEdu(
        Object.assign({}, edu, { education })
      );
      if (result) {
        this.GetDocEdus({ docBaseSn: edu.docBaseSn });
        this.$swal.fire({
          title: "更新學歷成功",
          icon: "success",
          confirmButtonText: "關閉",
        });
      } else {
        this.$swal.fire({
          title: "更新失敗",
          icon: "error",
          confirmButtonText: "關閉",
        });
      }
    },
    async deleteDocEdu(edu) {
      const result = await this.DeleteDocEdu(edu);
      if (result) {
        this.GetDocEdus({ docBaseSn: edu.docBaseSn });
        this.$swal.fire({
          title: "學歷已刪除",
          icon: "success",
          confirmButtonText: "關閉",
        });
      } else {
        this.$swal.fire({
          title: "刪除失敗",
          icon: "error",
          confirmButtonText: "關閉",
        });
      }
    },
    async createDocExp(experience) {
      const { docBaseSn } = this.form.docBase;
      const result = await this.CreateDocExp(
        Object.assign({}, { docBaseSn, experience })
      );
      if (result) {
        this.GetDocExps({ docBaseSn });
        this.$swal.fire({
          title: "新增經歷成功",
          icon: "success",
          confirmButtonText: "關閉",
        });
      } else {
        this.$swal.fire({
          title: "新增失敗",
          icon: "error",
          confirmButtonText: "關閉",
        });
      }
    },
    async updateDocExp(exp, experience) {
      const result = await this.UpdateDocExp(
        Object.assign({}, exp, { experience })
      );
      if (result) {
        this.GetDocExps({ docBaseSn: exp.docBaseSn });
        this.$swal.fire({
          title: "更新經歷成功",
          icon: "success",
          confirmButtonText: "關閉",
        });
      } else {
        this.$swal.fire({
          title: "更新失敗",
          icon: "error",
          confirmButtonText: "關閉",
        });
      }
    },
    async deleteDocExp(exp) {
      const result = await this.DeleteDocExp(exp);
      if (result) {
        this.GetDocExps({ docBaseSn: exp.docBaseSn });
        this.$swal.fire({
          title: "經歷已刪除",
          icon: "success",
          confirmButtonText: "關閉",
        });
      } else {
        this.$swal.fire({
          title: "刪除失敗",
          icon: "error",
          confirmButtonText: "關閉",
        });
      }
    },
    async createDocSkill(skill) {
      const { docBaseSn } = this.form.docBase;
      const result = await this.CreateDocSkill(
        Object.assign({}, { docBaseSn, skill })
      );
      if (result) {
        this.GetDocSkills({ docBaseSn });
        this.$swal.fire({
          title: "新增專長成功",
          icon: "success",
          confirmButtonText: "關閉",
        });
      } else {
        this.$swal.fire({
          title: "新增失敗",
          icon: "error",
          confirmButtonText: "關閉",
        });
      }
    },
    async updateDocSkill(docSkill, skill) {
      const result = await this.UpdateDocSkill(
        Object.assign({}, docSkill, { skill })
      );
      if (result) {
        this.GetDocSkills({ docBaseSn: docSkill.docBaseSn });
        this.$swal.fire({
          title: "更新專長成功",
          icon: "success",
          confirmButtonText: "關閉",
        });
      } else {
        this.$swal.fire({
          title: "更新失敗",
          icon: "error",
          confirmButtonText: "關閉",
        });
      }
    },
    async deleteDocSkill(docSkill) {
      const result = await this.DeleteDocSkill(docSkill);
      if (result) {
        this.GetDocSkills({ docBaseSn: docSkill.docBaseSn });
        this.$swal.fire({
          title: "專長已刪除",
          icon: "success",
          confirmButtonText: "關閉",
        });
      } else {
        this.$swal.fire({
          title: "刪除失敗",
          icon: "error",
          confirmButtonText: "關閉",
        });
      }
    },
    async toggleShift(week, shift, room) {
      if (!this.shiftLoading) {
        const { docBaseSn } = this.form.docBase;
        const { shiftType } = this.form.docShift;
        const docShiftExist = this.docShifts.find(
          (docShift) =>
            docShift.week === week &&
            docShift.shift === shift &&
            docShift.room === room
        );
        this.shiftLoading = true;
        if (docShiftExist) {
          const result = await this.DeleteDocShift(
            Object.assign({}, docShiftExist)
          );
          if (result) {
            this.GetDocShifts({ docBaseSn });
            this.$swal.fire({
              title: "排班已刪除",
              icon: "success",
              confirmButtonText: "關閉",
            });
          } else {
            this.$swal.fire({
              title: "刪除失敗",
              icon: "error",
              confirmButtonText: "關閉",
            });
          }
          this.shiftLoading = false;
        } else {
          if (!shiftType) {
            this.$swal.fire({
              title: "未選班別",
              icon: "error",
              confirmButtonText: "關閉",
            });
            this.shiftLoading = false;
            return;
          }
          this.form.docShift.week = week;
          this.form.docShift.shift = shift;
          this.form.docShift.room = room;

          const result = await this.CreateDocShift(
            Object.assign({}, { docBaseSn }, this.form.docShift)
          );
          if (result) {
            this.GetDocShifts({ docBaseSn });
            this.$swal.fire({
              title: "新增排班成功",
              icon: "success",
              confirmButtonText: "關閉",
            });
          } else {
            this.$swal.fire({
              title: "新增失敗",
              icon: "error",
              confirmButtonText: "關閉",
            });
          }
          this.shiftLoading = false;
        }
      }
    },
    getShiftType({ week, shift, room }) {
      const docShifts = _.cloneDeep(this.docShifts);
      const docShift = docShifts.find(
        (docShift) =>
          docShift.week === week &&
          docShift.shift === shift &&
          docShift.room === room
      );
      if (!docShift) return null;
      const shiftType = this.shiftTypes.find(
        (shiftType) => shiftType.value === docShift.shiftType
      );
      return shiftType.memo;
    },
  },

  computed: {
    ...mapState(["pass", "weeks", "shifts", "shiftTypes", "rooms"]),
    ...mapGetters(["docBases", "docEdus", "docExps", "docSkills", "docShifts"]),
    baseURL() {
      return process.env.VUE_APP_BACKEND_BASE_URL;
    },
  },

  created() {
    if (!this.pass) this.$router.push({ name: "BackStage" });
  },

  async mounted() {
    await this.GetDocBases();
    await this.GetWeeks();
    await this.GetShifts();
    await this.GetShiftTypes();
    await this.GetRooms();
  },

  watch: {
    "dialog.createDocForm": function (n, o) {
      if (!n) {
        this.$refs.CreateDocBaseForm.reset();
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.text-h4
  font-family: Microsoft JhengHei
.mybtn
  position: relative
  border: 1px solid white
  border-radius: 4px
  padding-right: 2px
.mytr:hover
  background: transparent !important
  td:not(:first-child)
    background: #eeeeee !important
</style>
