<template lang="pug">
  .pt-8.pb-16
    .d-flex.justify-center
      div.d-inline-block
        v-img(src="/index-m-title-4.png" eager)
    .mt-8
      v-row.mx-2(:class="{ 'mx-16': $vuetify.breakpoint.mdAndUp }")
        v-col.pa-0(cols="12" md="6")
          v-card(flat)
            v-img(src="/index-mapv2.svg" eager)
        v-col.pa-0(cols="12" md="6")
          div(:class="{ 'ml-8': $vuetify.breakpoint.mdAndUp }")
            ReserveInfo
            .text-h6.mt-4 {{ $t('TrafficInfomation_1') }}
            .text-body-1 {{ $t('TrafficInfomation_2') }}#[v-icon(color="orange") mdi-circle-medium]{{ $t('TrafficInfomation_3') }}
            .text-body-1 {{ $t('TrafficInfomation_4') }}
            .text-h6.mt-4 {{ $t('TrafficInfomation_5') }}
            .text-body-1 #[v-icon(color="orange") mdi-circle-medium] {{ $t('TrafficInfomation_6') }}
            .text-body-1 #[v-icon(color="orange") mdi-circle-medium] {{ $t('TrafficInfomation_7') }}
            .text-h6.mt-4 {{ $t('TrafficInfomation_8') }}
            .text-body-1 {{ $t('TrafficInfomation_9') }}
            .text-body-1 {{ $t('TrafficInfomation_10') }}
            .text-body-1 {{ $t('TrafficInfomation_11') }}
</template>

<script>
import ReserveInfo from "@/components/ReserveInfo";

export default {
  name: "TrafficInfomation",

  components: { ReserveInfo },

  data() {
    return {};
  },
};
</script>

<style lang="sass" scoped></style>
