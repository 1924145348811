<template lang="pug">
  v-app
    v-app-bar(app :elevate-on-scroll="$vuetify.breakpoint.mdAndUp" :shrink-on-scroll="$vuetify.breakpoint.mdAndUp" color="white")
      div.d-flex.d-sm-flex.d-md-none.align-center.my-n1(style="height: inherit;")
        v-app-bar-nav-icon(@click.stop="drawer = !drawer")
      div.d-none.d-md-flex.align-center.my-n1(style="height: inherit;")
        a(href="/")
          v-img(src="/logo-m.webp" eager :to="{ name: 'Index' }")
      v-tabs.hidden-sm-and-down(optional right hide-slider color="orange lighten-1")
        v-tab.orange--text.text--lighten-1(:to="{ name: 'Reserve' }") {{ $t('App_11') }}
        v-tab.orange--text.text--lighten-1(:to="{ name: 'ReserveCheck' }") {{ $t('App_12') }}
        v-tab(:to="{ name: 'KnowUSoon' }") {{ $t('App_1') }}
        v-tab(:to="{ name: 'Service' }") {{ $t('App_2') }}
        v-menu(bottom left offset-y)
          template(v-slot:activator="{ on, attrs }")
            v-btn.align-self-center.grey--text.text--darken-1(text tile min-height="100%" v-bind="attrs" v-on="on") {{ $t('App_3') }}
              v-icon(right) mdi-menu-down
          v-list
            v-list-item(v-for="docBase in docBases" :key="docBase.docBaseSn" :to="{ name: 'Doctor', params: { uid: docBase.docBaseSn } }" exact-active-class="orange--text") {{ docBase.docName }} 醫師
        v-tab(:to="{ name: 'News' }") {{ $t('App_4') }}
        v-tab(:to="{ name: 'HeartRoad' }") {{ $t('App_5') }}
        v-tab(:to="{ name: 'ContactUs' }") {{ $t('App_6') }}
        v-tab.orange--text.text--lighten-1(:to="{ name: 'HowToReserve' }") {{ $t('App_7') }}
      v-spacer
      div.d-flex.align-center.my-n1(style="height: inherit;")
        v-speed-dial(v-model="i18nBtn" direction="bottom" transition="slide-y-transition")
          template(v-slot:activator)
            v-btn(icon v-model="i18nBtn")
              v-icon mdi-google-translate
          v-btn(v-for="locale in locales" :key="locale.lang" 
                fab 
                x-small 
                color="grey darken-1" 
                :depressed="$i18n.locale===locale.lang" 
                :dark="$i18n.locale===locale.lang" 
                :outlined="$i18n.locale!==locale.lang" 
                @click="changeLocale(locale.lang)") {{ locale.text }}
    v-navigation-drawer(v-model="drawer" app temporary)
      a(href="/")
        v-img.mt-6.mb-4(src="/logo-m.webp" eager)
      v-list(nav dense)
        v-list-item-group(v-model="group" active-class="orange--text text--accent-3")
          v-list-item(:to="{ name: 'Reserve' }")
            v-list-item-icon
              v-icon mdi-circle-small
            v-list-item-title.orange--text.text--lighten-1 {{ $t('App_11') }}
          v-list-item(:to="{ name: 'ReserveCheck' }") 
            v-list-item-icon
              v-icon mdi-circle-small
            v-list-item-title.orange--text.text--lighten-1 {{ $t('App_12') }}
          v-list-item(:to="{ name: 'KnowUSoon' }")
            v-list-item-icon 
              v-icon mdi-circle-small
            v-list-item-title {{ $t('App_1') }}
          v-list-item(:to="{ name: 'Service' }")
            v-list-item-icon 
              v-icon mdi-circle-small
            v-list-item-title {{ $t('App_2') }}
          v-list-group(prepend-icon="mdi-circle-small" no-action)
            template(v-slot:activator)
              v-list-item-title {{ $t('App_3') }}
            v-list-item(v-for="docBase in docBases" :key="docBase.docBaseSn" link :to="{ name: 'Doctor', params: { uid: docBase.docBaseSn } }")
              v-list-item-icon
                v-icon mdi-stethoscope
              v-list-item-title {{ docBase.docName }} 醫師
          v-list-item(:to="{ name: 'News' }")
            v-list-item-icon 
              v-icon mdi-circle-small
            v-list-item-title {{ $t('App_4') }}
          v-list-item(:to="{ name: 'HeartRoad' }")
            v-list-item-icon 
              v-icon mdi-circle-small
            v-list-item-title {{ $t('App_5') }}
          v-list-item(:to="{ name: 'ContactUs' }")
            v-list-item-icon 
              v-icon mdi-circle-small
            v-list-item-title {{ $t('App_6') }}
          v-list-item(:to="{ name: 'HowToReserve' }")
            v-list-item-icon 
              v-icon mdi-circle-small
            v-list-item-title.orange--text.text--lighten-1 {{ $t('App_7') }}
    v-main
      router-view
      v-btn(fab fixed right bottom :x-large="$vuetify.breakpoint.mdAndUp" color="orange lighten-1" @click="goPush('Reserve')" :style="fabStyle") 
        .d-flex.flex-column.justify-center.align-center
          div.white--text {{ $t("Reserve_9") }}
          div.white--text {{ $t("Reserve_30") }}
      v-btn(fab fixed right bottom :x-large="$vuetify.breakpoint.mdAndUp" color="orange lighten-1" @click="goPush('ReserveCheck')") 
        .d-flex.flex-column.justify-center.align-center
          div.white--text {{ $t("Reserve_31") }}
          div.white--text {{ $t("Reserve_9") }}
    v-footer.py-10.px-16.align-start
      v-col(cols="12" md="6") 
        .d-inline-block
          v-img(src="/footer-title-1_1.png" eager)
        div.mt-2.ml-1(style="white-space: pre-line;") {{ $t('App_10') }}
      v-col(cols="12" md="6" :class="{ 'mt-4': $vuetify.breakpoint.smAndDown }") 
        .d-inline-block
          v-img(src="/footer-title-3.png" eager)
        div.row.mt-0
          div.col-6.col-sm-3.d-flex.flex-column
            router-link.footer-link(:to="{ name: 'KnowUSoon' }") {{ $t('App_1') }}
            router-link.footer-link(:to="{ name: 'Reserve' }") {{ $t('App_11') }}
            router-link.footer-link(:to="{ name: 'Service' }") {{ $t('App_2') }}
            router-link.footer-link(:to="{ name: 'Doctor' }") {{ $t('App_3') }}
          div.col-6.col-sm-3.d-flex.flex-column
            router-link.footer-link(:to="{ name: 'News' }") {{ $t('App_4') }}
            router-link.footer-link(:to="{ name: 'HeartRoad' }") {{ $t('App_5') }}
            router-link.footer-link(:to="{ name: 'ContactUs' }") {{ $t('App_6') }}
            router-link.footer-link(:to="{ name: 'HowToReserve' }") {{ $t('App_7') }}
        div.mt-4.ml-n2
          v-btn.mr-2(v-if="socialLink.facebook" icon x-large @click="goto(socialLink.facebook)")
            v-icon(size="40" color="blue") mdi-facebook
          v-btn.mr-2(v-if="socialLink.instagram" icon x-large)
            v-icon(size="40") mdi-instagram
          v-btn.mr-2(v-if="socialLink.youtube" icon x-large)
            v-img(src="/footer-ico-youtube.png" max-width="36" eager)
          v-btn.mr-2(v-if="socialLink.line" icon x-large)
            v-img(src="/line.png" max-width="34" eager)
          v-btn.mr-2(v-if="socialLink.android" icon x-large @click="goto(socialLink.android)")
            v-img(src="/footer-ico-android.png" max-width="42" eager)
          v-btn.mr-2(v-if="socialLink.ios" icon x-large @click="goto(socialLink.ios)")
            v-img(src="/footer-ico-ios.png" max-width="42" eager)
    v-footer(dark padless)
      v-col.text-center.text-caption.grey--text(cols="12") 
        div {{ $t('App_9') }} © {{ new Date().getFullYear() }} All Rights Reserved
        div {{ $t('App_8') }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import socialLink from "../public/social-link.json";

export default {
  name: "App",

  data() {
    return {
      // 控制
      i18nBtn: false,
      drawer: false,
      group: null,

      // 初始資料
      locales: [
        { lang: "tw", text: "中" },
        { lang: "en", text: "En" },
        { lang: "jp", text: "日" },
        { lang: "cn", text: "简" },
      ],

      socialLink,
    };
  },

  methods: {
    ...mapActions(["GetDocBases"]),
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
    goto(url) {
      window.open(url);
    },
    goPush(name) {
      this.$router.push({ name });
    },
  },

  computed: {
    ...mapGetters(["docBases"]),
    fabStyle() {
      return {
        "margin-bottom": this.$vuetify.breakpoint.smAndDown ? "68px" : "84px",
      };
    },
  },

  watch: {
    group() {
      // this.drawer = false;
    },
  },

  async mounted() {
    await this.GetDocBases();
  },
};
</script>

<style lang="sass" scoped>
.footer-link
  text-decoration: none
  font-size: 18px
  color: #aaa !important
  transition: .5s
  margin-bottom: 4px
  &:hover
    color: orange !important
</style>
