<template lang="pug">
  .pt-8.pb-16.grey.lighten-5
    .d-flex.justify-center(v-if="isIndex")
      div.d-inline-block
        v-img(src="/index-title-1_1v2.png" eager)
    v-row.mx-2(v-else :class="{ 'mx-16': $vuetify.breakpoint.mdAndUp }")
      v-col.d-flex
        div
          v-img(src="/v-title.png" eager)
        div.ml-10.grey--text.text--darken-2
          .text-h4(style="white-space: pre-line;") {{ title_1 }}
          ul.mt-12
            li(v-for="item in list_1") 
              .text-h6 {{ item }}
      v-col
        v-img(src="/lef-1.png" eager)
      v-col(cols="12")
        .relative
          v-img(src="/lef-2.png" eager)
          .slogan(:class="{ 'm': $vuetify.breakpoint.xsOnly }") {{ slogan }}
        .my-content.grey--text.text--darken-1.mt-8.mx-1 {{ content1 }}
        .my-content.grey--text.text--darken-1.mt-4.mx-1 {{ content2 }}
      v-col.mt-10(cols="12" md="6")
        v-img(src="/lef-3.png" eager)
      v-col(cols="12" md="6" :class="{ 'mt-10': $vuetify.breakpoint.mdAndUp }")
        v-list.py-0(color="transparent")
          template(v-for="(item,idx) in list_2")
            v-list-item
              v-list-item-icon
                .d-inline-block
                  v-img(src="/ico-booking.png" eager)
              v-list-item-title 
                .text-h5.grey--text.text--darken-1 {{ item }}
            v-divider.my-1(v-if="idx+1 !== list_2.length")
      v-col.mt-8(cols="12")
        .text-h5(style="white-space: pre-line;") {{ consult }}
        v-divider.my-6
        ReserveInfo
    v-row.mx-2.mt-4(v-if="isIndex" :class="{ 'mx-16': $vuetify.breakpoint.mdAndUp, 'mt-12': !isIndex }")
      v-col(cols="12" md="4" v-for="(service,idx) in services" :key="idx" :class="{ 'mb-4': $vuetify.breakpoint.smAndDown }")
        v-card.relative
          v-img(:src="`/${service.pic}`" eager)
          .mask.d-flex.justify-center.align-center
            span.grey--text.text--darken-1 {{ service.text }}
</template>

<script>
import ReserveInfo from "@/components/ReserveInfo";

export default {
  name: "Service",

  components: {
    ReserveInfo,
  },

  props: {
    isIndex: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    title_1() {
      return this.$t("Service_1");
    },
    list_1() {
      return [
        this.$t("Service_2"),
        this.$t("Service_3"),
        this.$t("Service_4"),
        this.$t("Service_5"),
        this.$t("Service_6"),
        this.$t("Service_7"),
        this.$t("Service_8"),
        this.$t("Service_9"),
      ];
    },
    services() {
      return [
        { pic: "service-pic-01-80.webp", text: this.$t("Service_10") },
        { pic: "service-pic-02-80.webp", text: this.$t("Service_11") },
        { pic: "service-pic-03-80.webp", text: this.$t("Service_12") },
      ];
    },
    slogan() {
      return this.$t("Service_13");
    },
    content1() {
      return this.$t("Service_14");
    },
    content2() {
      return this.$t("Service_15");
    },
    list_2() {
      return [
        this.$t("Service_16"),
        this.$t("Service_17"),
        this.$t("Service_18"),
        this.$t("Service_19"),
        this.$t("Service_20"),
      ];
    },
    consult() {
      return this.$t("Service_21");
    },
  },
};
</script>

<style lang="sass" scoped>
.mask
  position: absolute
  top: 90%
  left: 15%
  bottom: -5%
  right: 15%
  background: rgba(255,255,255,0.8)
  border-radius: 4px
  box-shadow: 0 0 10px rgba(0,0,0,.2)

.relative
  position: relative

.slogan
  position: absolute
  top: 20%
  left: 5%
  right: 45%
  font-size: 1.8rem
  &.m
    font-size: 1rem

.my-content
  font-size: 1.1rem
  font-weight: 500
</style>
