import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Swal from "sweetalert2";

import VueI18n from "vue-i18n";
// import locales from "./plugins/locales";
import locales from "../public/locales";

Vue.config.productionTip = false;

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "tw", // set locale
  messages: locales, // set locale messages
});

Vue.prototype.$swal = Swal;
window._ = require("lodash");

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
