export default {
  App_1: "佑昇を知る",
  App_2: "診療項目",
  App_3: "医療チーム",
  App_4: "生殖に関する新しい知識",
  App_5: "心の旅",
  App_6: "お問い合わせ",
  App_7: "携帯App",
  App_8: "許可なく転載することはできません",
  App_9: "ゆうしょう診療所",
  App_10: `10491 台北市中山区長安東路二段88号8階
    
  電話：02-2517-6696 または法人電話：0912-530756
 
  月曜日 08:00 - 22:00
  火曜日 08:00 - 17:00
  水曜日 08:00 - 22:00
  木曜日 08:00 - 17:00
  金曜日 08:00 - 17:00
  土曜日 08:00～22:00
  日曜日定休`,
  App_11: "予約登録",
  App_12: "チェック予約",
  App_13: "予約関連",
  OurTeam_1: "学歴",
  OurTeam_2: "経験",
  OutPatientTime_8: "モーニング相談 10:00～12:00 11:30 受付・登録締切",
  OutPatientTime_11: "午後の相談 13:30～17:00 16:30 受付・登録締切",
  OutPatientTime_12: "夜間相談 18:00～21:30 21:00 登録・登録締切",
  ReserveInfo_1: "予約ライン",
  ReserveInfo_2: "10491 台北市中山区長安東路二段88号8階",
  TrafficInfomation_1: "MRTに乗る",
  TrafficInfomation_2: "グリーン松山新店線",
  TrafficInfomation_3: "黄色のニュートラル化された新しいリード ライン",
  TrafficInfomation_4: "松江南京駅4番出口、徒歩約5分。",
  TrafficInfomation_5: "駐車場のご案内",
  TrafficInfomation_6: "建国高架駐車場 1時間30元 07:00-21:30",
  TrafficInfomation_7:
    "エバーグリーン ホテルの中興トゥドゥ ルーム、1 時間あたり 50 元、24 時間営業",
  TrafficInfomation_8: "診療時間",
  TrafficInfomation_9: "月曜日、水曜日、土曜日 08:00-22:00",
  TrafficInfomation_10: "火曜日、木曜日、金曜日 08:00-17:00",
  TrafficInfomation_11: "日曜定休",
  Doctor_1: "専門知識",
  KnowUSoon_1: "Usoon を選んだ理由",
  KnowUSoon_2: "台湾最高峰の装備を誇らしげに眺める",
  KnowUSoon_3: `最新のIVF機器を選択してください
    発生学者の優秀なチーム
    成功率に対する患者の満足度
    優秀な医師集団
    絶妙なパーソナライズされたフレンドリーな医療`,
  KnowUSoon_4: `Usoon Clinic は 2019 年 6 月にオープンし、最新の生殖医療技術と最先端の医療機器を提供し、国内トップの生殖医療チームと協力して、最高の診断と治療サービスを提供しています。
                  
    不妊治療センターには、HEPA 高効率微粒子エア フィルター、短波紫外線 (UV-C) ランプ、活性炭フィルターが装備されており、空気中の粒子だけでなく、皮膚の破片、繊維、髪、バクテリアなどの外部汚染物質も除去します。 .高水準の無菌でほこりのない実験室を確保してください。
    
    私たちの理念: 生殖産業のリーダーとして、Yousheng は常に整合性管理、顧客第一、卓越性への努力の概念を遵守し、顧客が世界の先進的で高品質の医療サービスを享受できるようにするために、私たちは引き続きすべての顧客が希望の道を歩み始め、優生学と妊娠の優れたブランドを作成するよう努めています。`,
  News_1: "--選んでください--",
  News_2: "関連記事",
  News_3: "キーワード検索",
  News_4: "名",
  News_5: "もっと",
  News_6: "日付別",
  News_7: "ビュー別",
  News_8: "医師によると",
  News_9: "テーマ別",
  News_10: "すべての記事",
  Service_1: `命をつないで
    幸せが続きますように`,
  Service_2: "不妊検査と治療の統合",
  Service_3: "人工授精、家族計画",
  Service_4: "試験管ベビーと卵子凍結相談",
  Service_5: "卵管検査と浚渫",
  Service_6: "子宮と子宮頸部の検査",
  Service_7: "精液検査処理と人工授精",
  Service_8: "習慣流産チェック",
  Service_9: "着床前遺伝子診断など",
  Service_10: "不妊検査と治療の統合",
  Service_11: "新世代の試験管ベビー",
  Service_12: "試験管ベビーと卵子凍結相談",
  Service_13:
    "あなたは知っていますか？結婚しているカップルの平均 10 ～ 15% が不妊症に苦しんでいます。あなたは一人ではありません!",
  Service_14:
    "テストを受ける必要がありますか？ 「多くの子供、多くの孫、多くの祝福」と「不貞には3つの方法があり、子孫を持たないことが最も素晴らしい」は、現代のハイテクで競争力のある経済社会のニーズを満たしていませんが、しばしば登場します「早産」の結婚式のスピーチ。ほとんどの既婚女性は今でも、年長者や親戚、友人の世話をすることがあります。結婚して1年、週に2回以上セックスをしている方で、まだ妊娠していない方は不妊の心配があるかもしれません. 夫婦の10%~15%と推定されています.一般的に、30 歳以上の既婚女性は、より積極的なチェックを行うことが推奨されます。",
  Service_15:
    "人間の生殖には未だ解明されていない謎が多いですが、人工授精や試験管ベビーなどの現代の人工生殖医療の進歩により、妊娠率は50～60%にまで上昇しています。",
  Service_16: "排卵の予測と排卵の刺激",
  Service_17: "卵管検査と浚渫",
  Service_18: "子宮と子宮頸部の検査",
  Service_19: "精液検査・加工・人工授精",
  Service_20: "着床前遺伝子診断など",
  Service_21: `私たちの病院には豊富な経験とシニアエリートチームがあります
    あなたに奉仕することができます、相談のために病院を訪問することを歓迎します`,
  Reserve_1: "開始日",
  Reserve_2: "終了日",
  Reserve_3: "日付",
  Reserve_4: "朝",
  Reserve_5: "午後",
  Reserve_6: "夜",
  Reserve_7: "代診",
  Reserve_8: "医師",
  Reserve_9: "予約する",
  Reserve_10: "予約する",
  Reserve_11: "ID番号と誕生日を入力してください",
  Reserve_12: "ID番号*",
  Reserve_13: "誕生日*",
  Reserve_14: "必須",
  Reserve_15: "キャンセル",
  Reserve_16: "確認する",
  Reserve_17: "成功",
  Reserve_18: "あなたの番号は",
  Reserve_19: "閉鎖",
  Reserve_20: "失敗",
  Reserve_21: "ご予約情報は以下の通りです",
  Reserve_22: "予約をキャンセルする",
  Reserve_23: "シフト",
  Reserve_24: "部屋",
  Reserve_25: "番号",
  Reserve_26: "この予約をキャンセルするかどうかを確認してください",
  Reserve_27: "初回のご相談はお名前と電話番号をご入力ください",
  Reserve_28: "名前*",
  Reserve_29: "電話番号*",
  Reserve_30: "",
  Reserve_31: "確認",
};
