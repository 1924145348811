<template lang="pug">
  div
    .d-flex.justify-space-between.align-center.pl-2.mb-1
      .text-h5 輪播圖
      v-dialog(v-model="dialog.createForm" width="320")
        template(v-slot:activator="{ on, attrs }")
          v-btn(v-bind="attrs" v-on="on" outlined color="blue") 新增
        v-card
          v-card-title.text-h6 新增輪播圖
          v-card-text
            v-form(ref="CreateForm" @submit.prevent)
              v-text-field(v-model="form.bannerName" :counter="100" :maxlength="100" label="輪播圖名稱(非必填)" outlined)
          v-card-actions.px-6
            v-spacer
            v-btn(text color="blue" @click="create") 新增
    v-divider
    v-row.mt-0
      v-col(cols="4")
        v-card(outlined)
          v-list(dense nav)
            v-list-item-group(v-model="selectedBanner" active-class="blue lighten-5 blue--text" @change="bannerOnChange")
              v-list-item(v-for="banner in banners" :key="banner.bannerSn" link)
                v-list-item-avatar
                  v-img(:src="banner.filePath?baseURL+banner.filePath:'/blog_normal.png'")
                    template(v-slot:placeholder)
                      v-row.fill-height.ma-0(align="center" justify="center")
                        v-progress-circular(indeterminate color="primary" size="16" width="2")
                v-list-item-content
                  v-list-item-title {{ banner.bannerName }}
      v-col(cols="8")
        template(v-if="selectedBanner!=null")
          .d-flex.align-center
            v-btn(outlined color="error" @click="deleteBanner") 刪除
            v-spacer
            v-dialog(v-model="dialog.updateForm" width="320")
              template(v-slot:activator="{ on, attrs }")
                v-btn(v-bind="attrs" v-on="on" outlined color="orange") 更新
              v-card
                v-card-title.text-h6 更新輪播圖
                v-card-text
                  v-form(ref="UpdateForm" @submit.prevent)
                    v-text-field(v-model="form.bannerName" :counter="100" :maxlength="100" label="輪播圖名稱(非必填)" outlined)
                v-card-actions.px-6
                  v-spacer
                  v-btn(text color="blue" @click="update") 送出
            v-switch.ma-0.pa-0.ml-3(v-model="form.enable" :label="form.enable?'啟用':'停用'" hide-details @change="updateEnable")
          v-card.mt-1.pa-4(outlined)
            .d-flex.justify-space-between.align-center
              v-card-title.pa-0.pl-2 
                span.mr-2 電腦版
              v-btn(v-if="isChange.file" text color="blue" @click="updateBannerLarge") 儲存
            v-hover
              template(v-slot:default="{ hover }")
                v-img.mt-1.rounded(:src="form.filePath?(form.filePath.indexOf('base64')>-1?'':baseURL)+form.filePath:'/blog_normal.png'" contain)
                  template(v-slot:placeholder)
                    v-row.fill-height.ma-0(align="center" justify="center")
                      v-progress-circular(indeterminate color="primary" width="4")
                  v-fade-transition
                    v-overlay(v-if="hover" absolute color="#111111")
                      .d-flex.align-center.mybtn
                        v-file-input.pa-0.ma-0(v-model="form.file" id="file-input" hide-input @change="fileOnChange") 
                        label.absolute(for="file-input" style="cursor: pointer;") 上傳照片
          v-card.mt-1.pa-4(outlined)
            .d-flex.justify-space-between.align-center
              v-card-title.pa-0.pl-2 
                span.mr-2 手機版
              v-btn(v-if="isChange.fileM" text color="blue" @click="updateBannerMedium") 儲存
            v-hover
              template(v-slot:default="{ hover }")
                v-img.mt-1.rounded(:src="form.filePathM?(form.filePathM.indexOf('base64')>-1?'':baseURL)+form.filePathM:'/blog_normal.png'" contain)
                  template(v-slot:placeholder)
                    v-row.fill-height.ma-0(align="center" justify="center")
                      v-progress-circular(indeterminate color="primary" width="4")
                  v-fade-transition
                    v-overlay(v-if="hover" absolute color="#111111")
                      .d-flex.align-center.mybtn
                        v-file-input.pa-0.ma-0(v-model="form.file" id="file-input" hide-input @change="fileMOnChange") 
                        label.absolute(for="file-input" style="cursor: pointer;") 上傳照片
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "Banners",

  data() {
    return {
      dialog: {
        createForm: false,
        updateForm: false,
      },
      form: {
        bannerSn: null,
        bannerName: null,
        file: null,
        fileName: null,
        filePath: null,
        fileNameM: null,
        filePathM: null,
        enable: null,
      },
      fileBase64: null,
      selectedBanner: null,
      isChange: { file: false, fileM: false },
    };
  },

  methods: {
    ...mapActions([
      "GetBanners",
      "CreateBanner",
      "UpdateBanner",
      "UpdateBannerLarge",
      "UpdateBannerMedium",
      "UpdateBannerEnable",
      "DeleteBanner",
    ]),
    reset() {
      this.form = {
        bannerSn: null,
        bannerName: null,
        file: null,
        fileName: null,
        filePath: null,
        fileNameM: null,
        filePathM: null,
        enable: null,
      };
    },
    bannerOnChange() {
      this.reset();

      const banners = _.cloneDeep(this.banners);
      this.form = Object.assign(this.form, banners[this.selectedBanner]);
    },
    fileOnChange() {
      if (this.form.file) {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.form.filePath = reader.result;
          },
          false
        );
        reader.readAsDataURL(this.form.file);
      }
      this.isChange.file = true;
    },
    fileMOnChange() {
      if (this.form.file) {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.form.filePathM = reader.result;
          },
          false
        );
        reader.readAsDataURL(this.form.file);
      }
      this.isChange.fileM = true;
    },
    async create() {
      const result = await this.CreateBanner(this.form);
      if (result) {
        this.$refs.CreateForm.reset();
        this.dialog.createForm = false;
        this.GetBanners();
        this.$swal.fire({
          title: "新增成功",
          icon: "success",
          confirmButtonText: "關閉",
        });
      } else {
        this.$swal.fire({
          title: "新增失敗",
          icon: "error",
          confirmButtonText: "關閉",
        });
      }
    },
    async update() {
      const result = await this.UpdateBanner(this.form);
      if (result) {
        this.$refs.UpdateForm.reset();
        this.dialog.updateForm = false;
        this.GetBanners();
        this.$swal.fire({
          title: "更新成功",
          icon: "success",
          confirmButtonText: "關閉",
        });
      } else {
        this.$swal.fire({
          title: "更新失敗",
          icon: "error",
          confirmButtonText: "關閉",
        });
      }
    },
    async updateEnable() {
      const result = await this.UpdateBannerEnable(this.form);
      if (result) {
        this.GetBanners();
        this.$swal.fire({
          title: `[${this.form.bannerName}] 已${
            this.form.enable ? "啟用" : "停用"
          }`,
          icon: "success",
          confirmButtonText: "關閉",
        });
        this.isChange.file = false;
      }
      await this.GetBanners();
    },
    async deleteBanner() {
      const confirm = await this.$swal.fire({
        title: `請確認是否刪除 [${this.form.bannerName}]`,
        text: "刪除後無法復原",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "確認",
        cancelButtonText: "取消",
      });
      if (confirm.isConfirmed) {
        const result = await this.DeleteBanner(this.form);
        if (result) {
          this.GetBanners();
          this.$swal.fire({
            title: "刪除成功",
            icon: "success",
            confirmButtonText: "關閉",
          });
        } else {
          this.$swal.fire({
            title: "刪除失敗",
            icon: "error",
            confirmButtonText: "關閉",
          });
        }
      }
    },
    async updateBannerLarge() {
      const { bannerSn, file } = this.form;
      const formData = new FormData();
      formData.append("file", file);
      const result = await this.UpdateBannerLarge({ bannerSn, formData });
      if (result) {
        this.GetBanners();
        this.$swal.fire({
          title: "更新成功",
          icon: "success",
          confirmButtonText: "關閉",
        });
        this.isChange.file = false;
      } else {
        this.$swal.fire({
          title: "更新失敗",
          icon: "error",
          confirmButtonText: "關閉",
        });
      }
    },
    async updateBannerMedium() {
      const { bannerSn, file } = this.form;
      const formData = new FormData();
      formData.append("file", file);
      const result = await this.UpdateBannerMedium({ bannerSn, formData });
      if (result) {
        this.GetBanners();
        this.$swal.fire({
          title: "更新成功",
          icon: "success",
          confirmButtonText: "關閉",
        });
        this.isChange.fileM = false;
      } else {
        this.$swal.fire({
          title: "更新失敗",
          icon: "error",
          confirmButtonText: "關閉",
        });
      }
    },
  },

  computed: {
    ...mapState(["pass"]),
    ...mapGetters(["banners"]),
    baseURL() {
      return process.env.VUE_APP_BACKEND_BASE_URL;
    },
  },

  watch: {
    "dialog.createForm": function (n, o) {
      if (!n) {
        this.$refs.CreateForm.reset();
      }
    },
    "dialog.updateForm": function (n, o) {
      if (!n) {
        this.$refs.UpdateForm.reset();
      }
    },
  },

  created() {
    if (!this.pass) this.$router.push({ name: "BackStage" });
  },

  async mounted() {
    await this.GetBanners();
  },
};
</script>
