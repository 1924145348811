import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = "/api/";
// Vue.axios.defaults.baseURL = "http://localhost:86/api/";

export default new Vuex.Store({
  state: {
    //#region 原主機API
    pass: false,
    weeks: [],
    shifts: [],
    shiftTypes: [],
    rooms: [],
    docBases: [],
    docBase: {},
    docEdus: [],
    docExps: [],
    docSkills: [],
    docShifts: [],
    allDocShifts: [],
    banners: [],
    heartRoads: [],
    //#endregion
    //#region 預約掛號
    schedules: [],
    //#endregion
  },
  mutations: {
    //#region 原主機API
    SetWeeks(state, payload) {
      state.weeks = payload;
    },
    SetShifts(state, payload) {
      state.shifts = payload;
    },
    SetShiftTypes(state, payload) {
      state.shiftTypes = payload;
    },
    SetRooms(state, payload) {
      state.rooms = payload;
    },
    SetDocBases(state, payload) {
      state.docBases = payload;
    },
    SetDocBase(state, payload) {
      state.docBase = payload;
    },
    SetPass(state, payload) {
      state.pass = payload;
    },
    SetDocEdus(state, payload) {
      state.docEdus = payload;
    },
    SetDocExps(state, payload) {
      state.docExps = payload;
    },
    SetDocSkills(state, payload) {
      state.docSkills = payload;
    },
    SetDocShifts(state, payload) {
      state.docShifts = payload;
    },
    SetAllDocShifts(state, payload) {
      state.allDocShifts = payload;
    },
    SetBanners(state, payload) {
      state.banners = payload;
    },
    SetHeartRoads(state, payload) {
      state.heartRoads = payload;
    },
    //#endregion
    //#region 預約掛號
    SetSchedules(state, payload) {
      state.schedules = payload;
    },
    //#endregion
  },
  actions: {
    //#region 原主機API
    async VerifyAuth(context, data) {
      try {
        const response = await Vue.axios.post("auth", data);
        context.commit("SetPass", true);
        return response;
      } catch (e) {
        return false;
      }
    },
    async GetWeeks(context) {
      try {
        const result = await Vue.axios.get("options/weeks");
        context.commit("SetWeeks", result.data);
      } catch (e) {
        return false;
      }
    },
    async GetShifts(context) {
      try {
        const result = await Vue.axios.get("options/shifts");
        context.commit("SetShifts", result.data);
      } catch (e) {
        return false;
      }
    },
    async GetShiftTypes(context) {
      try {
        const result = await Vue.axios.get("options/shiftTypes");
        context.commit("SetShiftTypes", result.data);
      } catch (e) {
        return false;
      }
    },
    async GetRooms(context) {
      try {
        const result = await Vue.axios.get("options/rooms");
        context.commit("SetRooms", result.data);
      } catch (e) {
        return false;
      }
    },
    async GetDocBases(context) {
      try {
        const result = await Vue.axios.get("docBases");
        context.commit("SetDocBases", result.data);
      } catch (e) {
        return false;
      }
    },
    async GetDocBase(context, data) {
      try {
        const result = await Vue.axios.get(`docBases/${data.docBaseSn}`);
        context.commit("SetDocBase", result.data);
      } catch (e) {
        return false;
      }
    },
    async CreateDocBase(context, data) {
      try {
        const response = await Vue.axios.post("docBases", data);
        return response;
      } catch (e) {
        return false;
      }
    },
    async UpdateDocBase(context, { docBaseSn, formData }) {
      try {
        const response = await Vue.axios.put(`docBases/${docBaseSn}`, formData);
        return response;
      } catch (e) {
        return false;
      }
    },
    async DeleteDocBase(context, data) {
      try {
        const response = await Vue.axios.delete(`docBases/${data.docBaseSn}`);
        return response;
      } catch (e) {
        return false;
      }
    },
    async GetDocEdus(context, { docBaseSn }) {
      try {
        const result = await Vue.axios.get(`docBases/${docBaseSn}/edus`);
        context.commit("SetDocEdus", result.data);
      } catch (e) {
        return false;
      }
    },
    async CreateDocEdu(context, data) {
      try {
        const response = await Vue.axios.post(
          `docBases/${data.docBaseSn}/edus`,
          data
        );
        return response;
      } catch (e) {
        return false;
      }
    },
    async UpdateDocEdu(context, data) {
      try {
        const response = await Vue.axios.put(
          `docBases/${data.docBaseSn}/edus/${data.docEduSn}`,
          data
        );
        return response;
      } catch (e) {
        return false;
      }
    },
    async DeleteDocEdu(context, data) {
      try {
        const response = await Vue.axios.delete(
          `docBases/${data.docBaseSn}/edus/${data.docEduSn}`
        );
        return response;
      } catch (e) {
        return false;
      }
    },
    async GetDocExps(context, { docBaseSn }) {
      try {
        const result = await Vue.axios.get(`docBases/${docBaseSn}/exps`);
        context.commit("SetDocExps", result.data);
      } catch (e) {
        return false;
      }
    },
    async CreateDocExp(context, data) {
      try {
        const response = await Vue.axios.post(
          `docBases/${data.docBaseSn}/exps`,
          data
        );
        return response;
      } catch (e) {
        return false;
      }
    },
    async UpdateDocExp(context, data) {
      try {
        const response = await Vue.axios.put(
          `docBases/${data.docBaseSn}/exps/${data.docExpSn}`,
          data
        );
        return response;
      } catch (e) {
        return false;
      }
    },
    async DeleteDocExp(context, data) {
      try {
        const response = await Vue.axios.delete(
          `docBases/${data.docBaseSn}/exps/${data.docExpSn}`
        );
        return response;
      } catch (e) {
        return false;
      }
    },
    async GetDocSkills(context, { docBaseSn }) {
      try {
        const result = await Vue.axios.get(`docBases/${docBaseSn}/skills`);
        context.commit("SetDocSkills", result.data);
      } catch (e) {
        return false;
      }
    },
    async CreateDocSkill(context, data) {
      try {
        const response = await Vue.axios.post(
          `docBases/${data.docBaseSn}/skills`,
          data
        );
        return response;
      } catch (e) {
        return false;
      }
    },
    async UpdateDocSkill(context, data) {
      try {
        const response = await Vue.axios.put(
          `docBases/${data.docBaseSn}/skills/${data.docSkillSn}`,
          data
        );
        return response;
      } catch (e) {
        return false;
      }
    },
    async DeleteDocSkill(context, data) {
      try {
        const response = await Vue.axios.delete(
          `docBases/${data.docBaseSn}/skills/${data.docSkillSn}`
        );
        return response;
      } catch (e) {
        return false;
      }
    },
    async GetDocShifts(context, { docBaseSn }) {
      try {
        const result = await Vue.axios.get(`docBases/${docBaseSn}/shifts`);
        context.commit("SetDocShifts", result.data);
      } catch (e) {
        return false;
      }
    },
    async CreateDocShift(context, data) {
      try {
        const response = await Vue.axios.post(
          `docBases/${data.docBaseSn}/shifts`,
          data
        );
        return response;
      } catch (e) {
        return false;
      }
    },
    async DeleteDocShift(context, data) {
      try {
        const response = await Vue.axios.delete(
          `docBases/${data.docBaseSn}/shifts/${data.docShiftSn}`
        );
        return response;
      } catch (e) {
        return false;
      }
    },
    async GetAllDocShifts(context) {
      try {
        const result = await Vue.axios.get(`shifts`);
        context.commit("SetAllDocShifts", result.data);
      } catch (e) {
        return false;
      }
    },
    async GetBanners(context) {
      try {
        const result = await Vue.axios.get("banners");
        context.commit("SetBanners", result.data);
      } catch (e) {
        return false;
      }
    },
    async GetEnableBanners(context, { enable }) {
      try {
        const result = await Vue.axios.get(`banners?enable=${enable}`);
        context.commit("SetBanners", result.data);
      } catch (e) {
        return false;
      }
    },
    async CreateBanner(context, data) {
      try {
        const response = await Vue.axios.post("banners", data);
        return response;
      } catch (e) {
        return false;
      }
    },
    async UpdateBanner(context, data) {
      try {
        const response = await Vue.axios.put(`banners/${data.bannerSn}`, data);
        return response;
      } catch (e) {
        return false;
      }
    },
    async UpdateBannerLarge(context, { bannerSn, formData }) {
      try {
        const response = await Vue.axios.put(`banners/${bannerSn}/l`, formData);
        return response;
      } catch (e) {
        return false;
      }
    },
    async UpdateBannerMedium(context, { bannerSn, formData }) {
      try {
        const response = await Vue.axios.put(`banners/${bannerSn}/m`, formData);
        return response;
      } catch (e) {
        return false;
      }
    },
    async UpdateBannerEnable(context, data) {
      try {
        const response = await Vue.axios.put(
          `banners/${data.bannerSn}/enable`,
          data
        );
        return response;
      } catch (e) {
        return false;
      }
    },
    async DeleteBanner(context, data) {
      try {
        const response = await Vue.axios.delete(`banners/${data.bannerSn}`);
        return response;
      } catch (e) {
        return false;
      }
    },
    async GetHeartRoads(context) {
      try {
        const result = await Vue.axios.get("heartRoads");
        context.commit("SetHeartRoads", result.data);
      } catch (e) {
        return false;
      }
    },
    //#endregion
    //#region 預約掛號
    async GetSchedules(context, { sDate, eDate }) {
      try {
        const result = await Vue.axios.get(
          `schedules?sDate=${sDate}&eDate=${eDate}`
        );
        if (result.data.回傳資訊.success) {
          context.commit("SetSchedules", result.data.排班資訊清單);
        }
      } catch (e) {
        return false;
      }
    },
    async ConfirmReserve(context, data) {
      try {
        const result = await Vue.axios.post(`reserves`, data);
        return result.data;
      } catch (e) {
        return false;
      }
    },
    async GetReserves(context, { id, bDate }) {
      try {
        const result = await Vue.axios.get(`reserves?id=${id}&bDate=${bDate}`);
        return result.data;
      } catch (e) {
        return false;
      }
    },
    async CancelReserve(context, data) {
      try {
        const result = await Vue.axios.post(`reserves/cancel`, data);
        return result.data;
      } catch (e) {
        return false;
      }
    },
    //#endregion
  },
  getters: {
    //#region 原主機API
    docBases(state) {
      return state.docBases.map((base) => ({
        docBaseSn: base.DocBaseSn,
        docName: base.DocName,
        fileName: base.FileName,
        filePath: base.FilePath,
      }));
    },
    docBase(state) {
      return {
        docBaseSn: state.docBase.DocBaseSn,
        docName: state.docBase.DocName,
        fileName: state.docBase.FileName,
        filePath: state.docBase.FilePath,
      };
    },
    docEdus(state) {
      return state.docEdus.map((edu) => ({
        docEduSn: edu.DocEduSn,
        docBaseSn: edu.DocBaseSn,
        education: edu.Education,
      }));
    },
    docExps(state) {
      return state.docExps.map((edu) => ({
        docExpSn: edu.DocExpSn,
        docBaseSn: edu.DocBaseSn,
        experience: edu.Experience,
      }));
    },
    docSkills(state) {
      return state.docSkills.map((edu) => ({
        docSkillSn: edu.DocSkillSn,
        docBaseSn: edu.DocBaseSn,
        skill: edu.Skill,
      }));
    },
    docShifts(state) {
      return state.docShifts.map((shift) => ({
        docBaseSn: shift.DocBaseSn,
        docShiftSn: shift.DocShiftSn,
        shift: shift.Shift,
        shiftType: shift.ShiftType,
        week: shift.Week,
        room: shift.Room,
      }));
    },
    allDocShifts(state) {
      return state.allDocShifts.map((shift) => ({
        docBaseSn: shift.DocBaseSn,
        docName: shift.DocName,
        docShiftSn: shift.DocShiftSn,
        shift: shift.Shift,
        shiftType: shift.ShiftType,
        week: shift.Week,
        room: shift.Room,
      }));
    },
    banners(state) {
      return state.banners.map((banner) => ({
        bannerName: banner.BannerName,
        bannerSn: banner.BannerSn,
        fileName: banner.FileName,
        filePath: banner.FilePath,
        fileNameM: banner.FileNameM,
        filePathM: banner.FilePathM,
        enable: banner.Enable,
      }));
    },
    heartRoads(state) {
      return state.heartRoads.map((heartRoad) => ({
        heartRoadSn: heartRoad.HeartRoadSn,
        createdTime: heartRoad.CreatedTime,
        fullPicture: heartRoad.FullPicture,
        message: heartRoad.Message,
        shares: heartRoad.Shares,
        reactions: heartRoad.Reactions,
        reactionType: heartRoad.ReactionType,
        permalinkUrl: heartRoad.PermalinkUrl,
        messageTags: heartRoad.MessageTags.map((messageTag) => ({
          messageTagSn: messageTag.MessageTagSn,
          heartRoadSn: messageTag.HeartRoadSn,
          name: messageTag.Name,
        })),
      }));
    },
    //#endregion
  },
  modules: {},
});
