export default {
  App_1: "Usoon",
  App_2: "Services",
  App_3: "Our Team",
  App_4: "News",
  App_5: "Journey",
  App_6: "Contact Us",
  App_7: "Mobile App",
  App_8: "Unauthorized reproduction or republication is prohibited",
  App_9: "Usoon Fertility Center",
  App_10: `8th Floor, No. 88, Section 2, Chang'an East Road, Zhongshan District, Taipei City 10491
    
  Phone: 02-2517-6696 or business phone: 0912-530756
 
  Monday 08:00 - 22:00
  Tuesday 08:00 - 17:00
  Wednesday 08:00 - 22:00
  Thursday 08:00 - 17:00
  Friday 08:00 - 17:00
  Saturday 08:00 - 22:00
  Closed on Sunday`,
  App_11: "Appointment",
  App_12: "Check Appointment",
  App_13: "Appointment",
  OurTeam_1: "Educational background.",
  OurTeam_2: "Work experience.",
  OutPatientTime_8:
    "Morning consultation 10:00-12:00 11:30 deadline for registration/report",
  OutPatientTime_11:
    "afternoon consultation 13:30-17:00 16:30 deadline for registration/report",
  OutPatientTime_12:
    "Evening consultation 18:00-21:30 21:00 Deadline for registration/registration",
  ReserveInfo_1: "Reservation line",
  ReserveInfo_2:
    "8th Floor, No. 88, Section 2, Changan East Road, Zhongshan District, Taipei City, 10491",
  TrafficInfomation_1: "Take the MRT",
  TrafficInfomation_2: "Green Songshan Xindian Line",
  TrafficInfomation_3: "Yellow and new reed lines",
  TrafficInfomation_4:
    "Songjiang Nanjing Station Exit 4, walk for about 5 minutes. ",
  TrafficInfomation_5: "Parking Information",
  TrafficInfomation_6:
    "Jianguo elevated parking lot 30 yuan per hour 07:00-21:30",
  TrafficInfomation_7:
    "Evergreen Hotel Zhongxing Tudu Room 50 yuan per hour, open 24 hours",
  TrafficInfomation_8: "Outpatient hours",
  TrafficInfomation_9: "Monday, Wednesday, Saturday 08:00-22:00",
  TrafficInfomation_10: "Tuesday, Thursday, Friday 08:00-17:00",
  TrafficInfomation_11: "Closed on Sunday",
  Doctor_1: "Expertise",
  KnowUSoon_1: "Why choose Usoon",
  KnowUSoon_2: "Taiwan's top equipment",
  KnowUSoon_3: `Select the latest IVF equipment
    Excellent team of embryologists
    Patient satisfaction with success rate
    An excellent group of doctors
    Exquisite personalized and friendly medical treatment`,
  KnowUSoon_4: `Usoon Clinic was opened in June 2019, providing the latest reproductive medical technology and cutting-edge medical equipment, combined with the top domestic reproductive medical team to provide you with the best diagnosis and treatment services.
                  
    The Fertility Center is equipped with HEPA high-efficiency particulate air filters, short-wave ultraviolet (UV-C) lamps, and activated carbon filters to remove airborne particles as well as external pollutants such as skin debris, fibers, hair, bacteria, etc. Ensure a high-standard sterile and dust-free laboratory.
    
    Our Philosophy: As a leader in the reproductive industry, Usoon has always adhered to the concept of integrity management, customer first, and striving for excellence. In order to allow customers to enjoy the world's advanced and high-quality medical services, we continue to develop and integrate resources from all parties, hoping to serve Every customer starts the road of hope, and strives to create an excellent brand of eugenics and pregnancy!`,
  News_1: "--Please choose--",
  News_2: "Related articles",
  News_3: "Keyword search",
  News_4: "Views",
  News_5: "More",
  News_6: "By date",
  News_7: "By the number of views",
  News_8: "According to the doctor",
  News_9: "By topic",
  News_10: "All articles",
  Service_1: `Let life pass on
    Let happiness continue`,
  Service_2: "Integrated infertility examination and treatment",
  Service_3: "Artificial insemination, family planning",
  Service_4: "Consultation related to test tube baby and egg freezing",
  Service_5: "Oviduct inspection and dredging",
  Service_6: "Uterus and cervix examination",
  Service_7: "Semen inspection processing and artificial insemination",
  Service_8: "Examination of habitual abortion",
  Service_9: "Preimplantation genetic diagnosis, etc.",
  Service_10: "Integrated infertility examination and treatment",
  Service_11: "A new generation of test-tube babies",
  Service_12: "Consultation related to test tube baby and egg freezing",
  Service_13:
    "Did you know? An average of 10 to 15 percent of married couples suffer from infertility, and you are not alone! ",
  Service_14:
    'Do I need to do testing? "Many children, many grandchildren, many blessings" and "there are three ways to be unfilial, and having no offspring is the greatest", although they no longer meet the needs of modern high-tech and competitive economic society, they often appear in the wedding speech of "early birth". Most married women still receive the caring eyes of their elders or relatives and friends from time to time. If you have been married for one year and have sex more than twice a week, if you are still not pregnant, you may have concerns about infertility. It is estimated that 10% to 15% of married couples. It is generally recommended that married women over the age of 30 can do some more active checks. ',
  Service_15:
    "Human reproduction still has many unsolved mysteries, but the advancement of modern artificial reproductive medicine, including artificial insemination and test-tube babies, has increased the pregnancy rate to 50-60%. ",
  Service_16: "Ovulation prediction and ovulation stimulation",
  Service_17: "Check and unblock fallopian tubes",
  Service_18: "Uterus and cervix examination",
  Service_19: "Semen inspection, processing and artificial insemination",
  Service_20: "Preimplantation genetic diagnosis, etc.",
  Service_21: `Our hospital has rich experience and a senior elite team
    Can serve you, welcome to visit the hospital for consultation`,
  Reserve_1: "Start Date",
  Reserve_2: "End Date",
  Reserve_3: "Date",
  Reserve_4: "Morning",
  Reserve_5: "Afternoon",
  Reserve_6: "Night",
  Reserve_7: "Substitute Diagnosis",
  Reserve_8: "Doctor",
  Reserve_9: "Reserve ",
  Reserve_10: "Reserve ",
  Reserve_11: "Please enter your ID number and birthday",
  Reserve_12: "ID number*",
  Reserve_13: "Birthday*",
  Reserve_14: "Required",
  Reserve_15: "Cancel",
  Reserve_16: "Confirm ",
  Reserve_17: "Success",
  Reserve_18: "Your number is",
  Reserve_19: "Close",
  Reserve_20: "Fail",
  Reserve_21: "Your reservation information is as follows",
  Reserve_22: "Cancel Reserve ",
  Reserve_23: "Shift",
  Reserve_24: "Room",
  Reserve_25: "Number",
  Reserve_26: "Please confirm whether to cancel this appointment",
  Reserve_27: "For first consultation, please enter your name and phone number",
  Reserve_28: "Name*",
  Reserve_29: "Phone*",
  Reserve_30: "",
  Reserve_31: "Check",
};
