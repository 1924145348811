<template lang="pug">
  .pt-8.pb-16
    .d-flex.justify-center
      .d-inline-block
        v-img(src="/index-m-title-2.png" eager)
    .mx-2.mt-4(:class="{ 'mx-16': $vuetify.breakpoint.mdAndUp }")
      v-row
        template(v-for="(doctor,idx) in doctors")
          v-col.mt-2(cols="4" md="2")
            v-img(:src="`${doctor.url}`" eager)
          v-col(cols="8" md="4")
            .pl-2
              DocName(:name="doctor.name" smallTag)
              .text-h6.mt-2.my-subtitle {{ $t('OurTeam_1') }}
              span.my-content.grey--text.text--darken-2(style="white-space: pre-line;" v-html="doctor.edu")
              .text-h6.mt-2.my-subtitle {{ $t('OurTeam_2') }}
              span.my-content.grey--text.text--darken-2(style="white-space: pre-line;" v-html="doctor.exp")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DocName from "@/components/common/DocName.vue";

export default {
  name: "OurTeam",

  components: {
    DocName,
  },

  data() {
    return {
      allDocExps: [],
      allDocEdus: [],
    };
  },

  computed: {
    ...mapGetters(["docBases", "docEdus", "docExps"]),
    baseURL() {
      return process.env.VUE_APP_BACKEND_BASE_URL;
    },
    doctors() {
      return this.docBases.map((docBase) => {
        let edus = this.allDocEdus
          .filter((edu) => edu.docBaseSn === docBase.docBaseSn)
          .map((edu) => edu.education)
          .join("<br/>");
        let exps = this.allDocExps
          .filter((exp) => exp.docBaseSn === docBase.docBaseSn)
          .map((exp) => exp.experience)
          .join("<br/>");
        return {
          url: docBase.filePath
            ? process.env.VUE_APP_BACKEND_BASE_URL + docBase.filePath
            : "",
          name: `${docBase.docName} 醫師`,
          edu: edus,
          exp: exps,
        };
      });
    },
  },

  methods: {
    ...mapActions(["GetDocExps", "GetDocEdus"]),
  },

  watch: {
    docBases: function (n, o) {
      if (n) {
        n.forEach(async (docBase) => {
          await this.GetDocExps({ docBaseSn: docBase.docBaseSn });
          this.allDocExps = [...this.allDocExps, ...this.docExps];
          await this.GetDocEdus({ docBaseSn: docBase.docBaseSn });
          this.allDocEdus = [...this.allDocEdus, ...this.docEdus];
        });
      }
    },
    immediate: true,
  },
};
</script>

<style lang="sass" scoped></style>
