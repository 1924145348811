<template lang="pug">
  span.doc-name(:class="{ 'small-tag': smallTag }") {{ name }}
</template>

<script>
export default {
  name: "DocName",

  props: {
    smallTag: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="sass" scoped>
.doc-name
  position: relative
  font-size: 1.8rem
  z-index: 1
  &::before
    content: ''
    position: absolute
    top: 70%
    left: -10%
    right: -60%
    bottom: -10%
    background: lighten(orange,20%)
    border-radius: 10% 100% 0 5%
    z-index: -1
  &.small-tag
    font-size: 1.6rem
  &.small-tag::before
    left: -5%
    right: -20%
    bottom: 0
</style>
