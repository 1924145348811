<template lang="pug">
  .py-8.grey.lighten-5
    .d-flex.justify-center
      div.d-inline-block
        v-img(src="/index-m-title-3.png" eager)
    div.mt-4
      .mt-card.overflow-hidden.mx-2(:class="{ 'mx-16': $vuetify.breakpoint.mdAndUp }")
        v-simple-table.transparent
          template(v-slot:default)
            thead
              tr
                th.text-center 診別
                th.text-center(v-for="week in weeks" :key="week.value") 星期{{ week.label }}
            tbody
              template(v-for="(shift, sidx) in shifts")
                tr.grey.lighten-4
                  td.b-double
                  td.text-center.b-double(:colspan="weeks.length-1") {{ marks[sidx].text1 }}
                  td.text-center.b-double {{ marks[sidx].text2 }}
                tr(v-for="(room, ridx) in rooms" :key="`${shift.value}-${room.value}`")
                  td.text-center(:class="{ 'b-dotted': ridx<rooms.length-1, 'b-double': ridx===rooms.length-1 }") {{ room.label }}
                  td(v-for="week in weeks" :key="`td-${shift.value}-${week.value}`" :class="{ 'b-dotted': ridx<rooms.length-1, 'b-double': ridx===rooms.length-1 }")
                    template(v-for="docShift in getDocShifts({ week: week.value, shift: shift.value, room: room.value })")
                      .d-flex.justify-center.align-center 
                        span {{ docShift.docName }}
                        v-img.ml-1(:src="getShiftTypeSrc(docShift.shiftType)" eager max-width="16") 
              tr
                td
                td 
                  div.d-flex.justify-center.align-center
                    span 約診 
                    v-img.ml-1(src="/ico-booking.png" eager max-width="16")
                td(:colspan="weeks.length-1")
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "OutPatientTime",

  data() {
    return {};
  },

  methods: {
    ...mapActions([
      "GetWeeks",
      "GetShifts",
      "GetShiftTypes",
      "GetRooms",
      "GetAllDocShifts",
    ]),
    getDocShifts({ week, shift, room }) {
      const allDocShifts = _.cloneDeep(this.allDocShifts);
      return allDocShifts.filter(
        (docShift) =>
          docShift.week === week &&
          docShift.shift === shift &&
          docShift.room === room
      );
    },
    getShiftTypeSrc(shiftType) {
      const shiftTypes = _.cloneDeep(this.shiftTypes);
      const s = shiftTypes.find(
        (s) => s.value === shiftType && s.label !== "固定班表"
      );
      if (s) return s.memo;
      return null;
    },
  },

  computed: {
    ...mapState(["weeks", "shifts", "shiftTypes", "rooms"]),
    ...mapGetters(["allDocShifts"]),
    marks() {
      return [
        // 上午註記
        {
          text1: "上午診 10:00-12:00 11:30 截止掛號/報到",
          text2: "上午診 09:00-12:00 11:30 截止掛號/報到",
        },
        // 下午註記
        { text1: "下午診 13:30-17:00 16:30 截止掛號/報到" },
        // 晚上註記
        { text1: "晚上診 18:00-21:30 21:00 截止掛號/報到" },
      ];
    },
  },

  async mounted() {
    await this.GetWeeks();
    await this.GetShifts();
    await this.GetShiftTypes();
    await this.GetRooms();
    await this.GetAllDocShifts();
  },
};
</script>

<style lang="sass" scoped>
.mt-card
  border: thin solid rgba(0, 0, 0, 0.12)
  border-radius: 24px 4px

td
  text-align: center
  white-space: nowrap
  &.t-double
    border-top: 2px double rgba(0, 0, 0, 0.12) !important
  &.b-double
    border-bottom: 2px double rgba(0, 0, 0, 0.12) !important
  &.t-dotted
    border-top: 1px dotted rgba(0, 0, 0, 0.12) !important
  &.b-dotted
    border-bottom: 1px dotted rgba(0, 0, 0, 0.12) !important
  &.l-solid
    border-left: 1px solid rgba(0, 0, 0, 0.12) !important
</style>
