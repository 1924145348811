<template lang="pug">
  v-container
    v-row
      v-col(cols="2" md="1")
        .d-flex.justify-center.align-center(style="height: 100%;")
          v-icon mdi-calendar
      v-col(cols="12" md="3")
        v-menu(v-model="sDateMenu" transition="scale-transition" offset-y min-width="auto")
          template(v-slot:activator="{ on, attrs }")
            v-text-field(v-model="sDate" :label="$t('Reserve_1')" hide-details readonly outlined dense v-bind="attrs" v-on="on")
          v-date-picker(v-model="sDate" no-title :min="minDate" @input="sDateMenu = false")
      v-col(cols="12" md="3")
        v-menu(v-model="eDateMenu" transition="scale-transition" offset-y min-width="auto")
          template(v-slot:activator="{ on, attrs }")
            v-text-field(v-model="eDate" :label="$t('Reserve_2')" hide-details readonly outlined dense v-bind="attrs" v-on="on")
          v-date-picker(v-model="eDate" no-title :min="minDate" :max="maxDate" @input="eDateMenu = false")
      v-col(cols="12" md="1")
        .d-flex.justify-center.align-center(style="height: 100%;")
          v-btn(outlined :block="$vuetify.breakpoint.smAndDown" color="blue" @click="onSearch")
            v-icon mdi-magnify

    .mt-card.overflow-hidden.my-6
      v-simple-table
        template(v-slot:default)
          thead.grey.lighten-5
            tr
              th.text-center {{ $t('Reserve_3') }}
              th.text-center(style="width: 30%;") {{ $t('Reserve_4') }}
              th.text-center(style="width: 30%;") {{ $t('Reserve_5') }}
              th.text-center(style="width: 30%;") {{ $t('Reserve_6') }}
          tbody
            template(v-for="(date) in scheduleDates")
              tr
                td.text-center
                  div(style="white-space: nowrap;") {{ date.label }}
                  div(style="white-space: nowrap;") {{ date.weekend }}
                td(v-for="num in 3") 
                  template(v-for="schedule in getSchedule(date.value, num===1?'早':num===2?'午':num===3?'晚':'')")
                    v-card.ma-4(outlined)
                      v-card-text.pb-0
                        div(style="white-space: nowrap;")
                          v-chip(color="blue-grey" small label outlined) {{ schedule.診別 }}
                          v-chip.ml-1(v-if="schedule.代班醫師名 !== '無'" color="green" small label outlined) {{ $t('Reserve_7') }}
                        div.mt-2(style="white-space: nowrap;")
                          span.text-h6.text--primary {{ schedule.代班醫師名 === '無' ? schedule.醫師名 : schedule.代班醫師名 }} 
                          span.text-subtitle-1 {{ $t('Reserve_8') }}
                      v-card-actions.px-4.pt-0.mt-2
                        v-spacer
                        v-btn(text color="primary" :disabled="schedule.可掛號否==='N'" @click.stop="openDialog(schedule)") {{ $t('Reserve_9') }}
                      div.px-4.pb-3(v-if="schedule.可掛號否==='N'")
                        v-alert.mb-0.px-2.py-1(outlined dense type="error") {{ schedule.就診參考序號 }}
    v-dialog(v-model="dialog" persistent max-width="480")
      v-card
        v-card-title.text-h5 {{ $t('Reserve_10') }}
        v-card-text.mt-2(v-if="schedule") 
          v-card.px-3.pt-3.pb-2(outlined)
            v-row
              v-col(cols="4")
                v-img(:src="getDocImage(schedule.代班醫師名 === '無' ? schedule.醫師名 : schedule.代班醫師名)?baseURL+getDocImage(schedule.代班醫師名 === '無' ? schedule.醫師名 : schedule.代班醫師名):'/blog_normal.png'" eager style="border-radius: 12px" aspect-ratio="1")
              v-col(cols="8")
                .d-flex.flex-wrap(style="gap: 4px;")
                  v-chip(color="blue-grey" small label outlined) {{ schedule.日期 }}
                  v-chip(color="blue-grey" small label outlined) {{ schedule.班別 }}
                  v-chip(color="blue-grey" small label outlined) {{ schedule.診別 }}
                  v-chip(v-if="schedule.代班醫師名 !== '無'" color="green" small label outlined) {{ $t('Reserve_7') }}
                div.mt-2(style="white-space: nowrap;")
                  span.text-h6.text--primary {{ schedule.代班醫師名 === '無' ? schedule.醫師名 : schedule.代班醫師名 }} 
                  span.text-subtitle-1 {{ $t('Reserve_8') }}
          v-form.mt-6(ref="form")
            span.text-subtitle-1 {{ $t('Reserve_11') }}
            v-text-field.mt-2(v-model="form.id" :label="$t('Reserve_12')" :rules="[v => !!v || `${$t('Reserve_12')}${$t('Reserve_14')}`]" hide-details="auto" outlined)
            v-menu(ref="bDateMenu" v-model="bDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto")
              template(v-slot:activator="{ on, attrs }")
                v-text-field.mt-3(v-model="form.bDate" :label="$t('Reserve_13')" :rules="[v => !!v || `${$t('Reserve_13')}${$t('Reserve_14')}`]" hide-details="auto" readonly outlined v-bind="attrs" v-on="on")
              v-date-picker(v-model="form.bDate" :active-picker.sync="activePicker" no-title locale="zh-tw" @change="saveBDate")
            span.text-subtitle-1.mt-2(v-if="isFirst") {{ $t('Reserve_27') }}
            v-text-field.mt-2(v-if="isFirst" v-model="form.name" :label="$t('Reserve_28')" :rules="[v => !!v || `${$t('Reserve_28')}${$t('Reserve_14')}`]" hide-details="auto" outlined)
            v-text-field.mt-2(v-if="isFirst" v-model="form.tel" :label="$t('Reserve_29')" :rules="[v => !!v || `${$t('Reserve_29')}${$t('Reserve_14')}`]" hide-details="auto" outlined)
        v-card-actions
          v-btn(text @click="closeDialog") {{ $t('Reserve_15') }}
          v-spacer
          v-btn(color="primary" text @click="confirmReserve(schedule.日期, schedule.排班識別碼)") {{ $t('Reserve_16') }}
</template>
<script>
import moment from "moment";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Reserve",

  data() {
    return {
      sDateMenu: false,
      eDateMenu: false,
      bDateMenu: false,
      sDate: moment(new Date()).format("YYYY-MM-DD"),
      eDate: moment(new Date()).add(7, "days").format("YYYY-MM-DD"),
      dialog: false,
      schedule: null,
      form: { id: null, bDate: null, name: null, tel: null },
      activePicker: null,
      isFirst: false,
    };
  },

  computed: {
    ...mapState(["schedules"]),
    ...mapGetters(["docBases"]),
    minDate() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    maxDate() {
      return moment(this.sDate, "YYYY-MM-DD")
        .add(30, "days")
        .format("YYYY-MM-DD");
    },
    scheduleDates() {
      moment.locale("zh-tw");
      return this.schedules
        .map((schedule) => schedule.日期)
        .filter(function (element, index, arr) {
          return arr.indexOf(element) === index;
        })
        .sort()
        .map((date) => ({
          value: date,
          label: moment(parseInt(date) + 19110000, "YYYYMMDD").format("MM/DD"),
          weekend: moment(parseInt(date) + 19110000, "YYYYMMDD").format("dddd"),
        }));
    },
    baseURL() {
      return process.env.VUE_APP_BACKEND_BASE_URL;
    },
  },

  methods: {
    ...mapActions(["GetSchedules", "ConfirmReserve"]),
    async onSearch() {
      if (this.sDate && this.eDate) {
        await this.GetSchedules({
          sDate: moment(this.sDate, "YYYY-MM-DD").format("YYYYMMDD"),
          eDate: moment(this.eDate, "YYYY-MM-DD").format("YYYYMMDD"),
        });
      }
    },
    getSchedule(date, shift) {
      const schedules = _.cloneDeep(this.schedules);
      return schedules.filter(
        (schedule) => schedule.日期 === date && schedule.班別 == shift
      );
    },
    openDialog(schedule) {
      this.schedule = schedule;
      this.dialog = true;
    },
    closeDialog() {
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.dialog = false;
    },
    saveBDate(date) {
      this.$refs.bDateMenu.save(date);
    },
    async confirmReserve(rDate, sid) {
      if (this.$refs.form.validate()) {
        let data = {
          id: this.form.id,
          bDate: moment(this.form.bDate, "YYYY-MM-DD").format("YYYYMMDD"),
          rDate: moment(parseInt(rDate) + 19110000, "YYYYMMDD").format(
            "YYYYMMDD"
          ),
          sid,
        };
        if (this.isFirst) {
          Object.assign(data, { name: this.form.name, tel: this.form.tel });
        }
        const result = await this.ConfirmReserve(data);

        if (result.回傳資訊.success) {
          const { id, bDate } = this.form;
          this.closeDialog();
          this.$swal
            .fire({
              title: `${this.$t("Reserve_9")}${this.$t("Reserve_17")}`,
              text: `${this.$t("Reserve_18")} ${result.掛號序號}`,
              icon: "success",
              confirmButtonText: this.$t("Reserve_19"),
            })
            .then((result) => {
              this.$router.push({
                name: "ReserveCheck",
                params: { id, bDate },
              });
            });
        } else {
          if (result.回傳資訊.code === "0028") {
            this.isFirst = true;
            return;
          }
          this.$swal.fire({
            title: `${this.$t("Reserve_9")}${this.$t("Reserve_20")}!`,
            html: result.回傳資訊.message,
            icon: "error",
            confirmButtonText: this.$t("Reserve_19"),
          });
        }
      }
    },
    getDocImage(docName) {
      const docBases = this.docBases;
      const docBase = docBases.find((d) => d.docName === docName);
      return docBase.filePath;
    },
  },

  async mounted() {
    await this.onSearch();
  },

  watch: {
    sDate: {
      async handler(n, o) {
        const sDate = moment(n, "YYYY-MM-DD");
        const eDate = moment(this.eDate, "YYYY-MM-DD");
        if (n && sDate > eDate) {
          this.eDate = moment(sDate).format("YYYY-MM-DD");
        }
      },
      immediate: true,
    },
    eDate: {
      async handler(n, o) {
        const sDate = moment(this.sDate, "YYYY-MM-DD");
        const eDate = moment(n, "YYYY-MM-DD");
        if (n && eDate < sDate) {
          this.sDate = moment(eDate).format("YYYY-MM-DD");
        }
      },
      immediate: true,
    },
    dialog: {
      async handler(n, o) {
        if (!n) {
          this.schedule = null;
        }
      },
      immediate: true,
    },
    bDateMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>
<style lang="sass" scoped>
.mt-card
  border: thin solid rgba(0, 0, 0, 0.12)
  border-radius: 24px 4px

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper)
  background: #fff

.v-card
  transition: .4s
</style>
