export default {
  App_1: "認識佑昇",
  App_2: "診療項目",
  App_3: "醫療團隊",
  App_4: "生殖新知",
  App_5: "心路歷程",
  App_6: "聯絡我們",
  App_7: "行動APP",
  App_8: "未經許可不得任意轉載",
  App_9: "佑昇診所",
  App_10: `10491 台北市中山區長安東路二段88號8樓 
    
    電話 02-2517-6696 or 公務手機 0912-530756
    
    週一 08:00 - 22:00
    週二 08:00 - 17:00
    週三 08:00 - 22:00
    週四 08:00 - 17:00
    週五 08:00 - 17:00
    週六 08:00 - 22:00
    週日休診`,
  App_11: "預約掛號",
  App_12: "查詢預約",
  App_13: "預約相關",
  OurTeam_1: "學歷",
  OurTeam_2: "經歷",
  OutPatientTime_8: "上午診 10:00-12:00 11:30 截止掛號/報到",
  OutPatientTime_11: "下午診 13:30-17:00 16:30 截止掛號/報到",
  OutPatientTime_12: "晚上診 18:00-21:30 21:00 截止掛號/報到",
  ReserveInfo_1: "預約專線",
  ReserveInfo_2: "10491 台北市中山區長安東路二段88號8樓",
  TrafficInfomation_1: "搭乘捷運",
  TrafficInfomation_2: "綠色松山新店線",
  TrafficInfomation_3: "黃色中和新蘆線",
  TrafficInfomation_4: "松江南京站4號出口，步行約5分鐘。",
  TrafficInfomation_5: "停車資訊",
  TrafficInfomation_6: "建國高架停車場 每小時 30元 07:00-21:30",
  TrafficInfomation_7: "長榮飯店中興嘟嘟房 每小時 50元 24小時營業",
  TrafficInfomation_8: "門診時間",
  TrafficInfomation_9: "週一、週三、週六 08:00-22:00",
  TrafficInfomation_10: "週二、週四、週五 08:00-17:00",
  TrafficInfomation_11: "週日休診",
  Doctor_1: "專長",
  KnowUSoon_1: "為何選擇佑昇",
  KnowUSoon_2: "傲視全台之頂尖設備",
  KnowUSoon_3: `精選最新的試管嬰兒設備
    優秀的胚胎師團隊
    病人對成功率的滿意度
    用心出色的醫師群
    精緻的個人化友善醫療`,
  KnowUSoon_4: `佑昇診所啟於2019年6月，提供目前最新的生殖醫療科技與最尖端的醫療設備，結合國內最頂尖生殖醫療團隊為您提供最好的診療服務。
                  
    生殖中心配備HEPA高效微粒空氣過濾器，短波紫外線(紫外線C)型燈，活性炭過濾器，清除空氣中傳播的粒子以及外部污染物，如皮膚碎屑、纖維、毛髮、細菌等。確保擁有高標準的無菌無塵實驗室。
    
    我們的理念：作為生殖產業的領跑者，佑昇始終堅持著誠信經營，客戶至上，力求卓越的理念，為了讓客戶享受到全球先進優質的醫療服務，我們不斷開拓，整合各方資源希望能夠為每一位客戶開啟希望之路，致力打造優生優孕優良品牌!`,
  News_1: "--請選擇--",
  News_2: "相關文章",
  News_3: "關鍵字搜尋",
  News_4: "人次",
  News_5: "更多",
  News_6: "依日期",
  News_7: "依瀏覽次數",
  News_8: "依醫師",
  News_9: "依主題",
  News_10: "全部文章",
  Service_1: `讓生命傳承
    讓幸福延續`,
  Service_2: "整合式不孕症檢查與治療",
  Service_3: "人工受孕、家庭計劃",
  Service_4: "試管嬰兒及凍卵相關諮詢",
  Service_5: "輸卵管檢查及疏通",
  Service_6: "子宮體及子宮頸檢查",
  Service_7: "精液檢查處理及人工授精",
  Service_8: "習慣性流產的檢查",
  Service_9: "著床前遺傳診斷等",
  Service_10: "整合式不孕症檢查與治療",
  Service_11: "新一代試管嬰兒",
  Service_12: "試管嬰兒及凍卵相關諮詢",
  Service_13:
    "您知道嗎？平均有百分之10至15左右的已婚夫婦都有不孕症的隱憂，您並不孤單！",
  Service_14:
    "我需要做檢測嗎？「多子多孫多福氣」，「不孝有三，無後為大」的古訓，雖然已不合現代高科技及競爭經濟社會的需求，但也常在「早生貴子」的婚禮祝詞出現。大多數的已婚婦女仍時時受到長輩或親友們的關愛眼神。結婚滿一年，一週大約有2次以上的性生活，如果仍沒有懷孕的話，則可能有不孕症的顧慮，約估已婚夫婦的百分之十至十五。一般建議年齡滿30歲以上的已婚婦女，可以做一些較積極的檢查。",
  Service_15:
    "人類的生殖仍有許多不解之謎，但是近代人工生殖醫學的進步包括人工授孕及試管嬰兒等治療方式已使受孕率增加為百分之50至60。",
  Service_16: "排卵的預測及刺激排卵",
  Service_17: "輸卵管的檢查及疏通",
  Service_18: "子宮體及子宮頸檢查",
  Service_19: "精液檢查，處理及人工授精",
  Service_20: "著床前遺傳診斷等",
  Service_21: `本院有豐富的經驗及資深精英團隊
    可以為您服務，歡迎蒞院諮詢`,
  Reserve_1: "起日",
  Reserve_2: "迄日",
  Reserve_3: "日期",
  Reserve_4: "早診",
  Reserve_5: "午診",
  Reserve_6: "晚診",
  Reserve_7: "代診",
  Reserve_8: "醫師",
  Reserve_9: "預約",
  Reserve_10: "進行預約掛號",
  Reserve_11: "請輸入身份證號及生日",
  Reserve_12: "身份證號*",
  Reserve_13: "生日*",
  Reserve_14: "必填",
  Reserve_15: "取消",
  Reserve_16: "確認",
  Reserve_17: "成功",
  Reserve_18: "您的看診號為",
  Reserve_19: "關閉",
  Reserve_20: "失敗",
  Reserve_21: "您的預約資料如下",
  Reserve_22: "退掛",
  Reserve_23: "班別",
  Reserve_24: "診別",
  Reserve_25: "看診號",
  Reserve_26: "請確認是否將此次預約進行退掛",
  Reserve_27: "初診請輸入姓名及電話",
  Reserve_28: "姓名*",
  Reserve_29: "電話*",
  Reserve_30: "掛號",
  Reserve_31: "查詢",
};
