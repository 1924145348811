import tw from "./tw";
import en from "./en";
import jp from "./jp";
import cn from "./cn";

export default {
  tw,
  en,
  jp,
  cn,
};
