<template lang="pug">
  v-carousel(continuous cycle hide-delimiter-background show-arrows-on-hover delimiter-icon="mdi-minus" height="100%") 
    v-carousel-item(v-for="(slide, i) in slides" :key="i" eager)
      v-img(:src="slide" eager)
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Carousel",

  data() {
    return {};
  },

  methods: {
    ...mapActions(["GetEnableBanners"]),
  },

  computed: {
    ...mapGetters(["banners"]),
    slides() {
      return this.$vuetify.breakpoint.smAndUp ? this.slidesL : this.slidesM;
    },
    slidesL() {
      const banners = this.banners;
      return banners.map((banner) =>
        banner.filePath
          ? process.env.VUE_APP_BACKEND_BASE_URL + banner.filePath
          : "/blog_normal.png"
      );
    },
    slidesM() {
      const banners = this.banners;
      return banners.map((banner) =>
        banner.filePathM
          ? process.env.VUE_APP_BACKEND_BASE_URL + banner.filePathM
          : "/blog_normal.png"
      );
    },
  },

  async mounted() {
    await this.GetEnableBanners({ enable: true });
  },
};
</script>
