import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-8 pb-16"},[_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"d-inline-block"},[_c(VImg,{attrs:{"src":"/index-m-title-4.png","eager":""}})],1)]),_c('div',{staticClass:"mt-8"},[_c(VRow,{staticClass:"mx-2",class:{ 'mx-16': _vm.$vuetify.breakpoint.mdAndUp }},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c(VCard,{attrs:{"flat":""}},[_c(VImg,{attrs:{"src":"/index-mapv2.svg","eager":""}})],1)],1),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('div',{class:{ 'ml-8': _vm.$vuetify.breakpoint.mdAndUp }},[_c('ReserveInfo'),_c('div',{staticClass:"text-h6 mt-4"},[_vm._v(_vm._s(_vm.$t('TrafficInfomation_1')))]),_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('TrafficInfomation_2'))),_c(VIcon,{attrs:{"color":"orange"}},[_vm._v("mdi-circle-medium")]),_vm._v(_vm._s(_vm.$t('TrafficInfomation_3')))],1),_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('TrafficInfomation_4')))]),_c('div',{staticClass:"text-h6 mt-4"},[_vm._v(_vm._s(_vm.$t('TrafficInfomation_5')))]),_c('div',{staticClass:"text-body-1"},[_c(VIcon,{attrs:{"color":"orange"}},[_vm._v("mdi-circle-medium")]),_vm._v(" "+_vm._s(_vm.$t('TrafficInfomation_6')))],1),_c('div',{staticClass:"text-body-1"},[_c(VIcon,{attrs:{"color":"orange"}},[_vm._v("mdi-circle-medium")]),_vm._v(" "+_vm._s(_vm.$t('TrafficInfomation_7')))],1),_c('div',{staticClass:"text-h6 mt-4"},[_vm._v(_vm._s(_vm.$t('TrafficInfomation_8')))]),_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('TrafficInfomation_9')))]),_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('TrafficInfomation_10')))]),_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('TrafficInfomation_11')))])],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }