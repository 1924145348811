<template lang="pug">
  div.d-flex.justify-center
    div.overflow-hidden
      div.d-inline-block.mt-n12
        v-img(src="/kk-1.png" eager)
</template>

<script>
export default {
  name: "MainTitle",
};
</script>
