<template lang="pug">
  v-container 
    v-form(ref="form")
      v-row
        v-col(cols="12" md="4")
          v-text-field(v-model="form.id" :label="$t('Reserve_12')" :rules="[v => !!v || $t('Reserve_12')+$t('Reserve_14')]" hide-details outlined)
        v-col(cols="12" md="4")
          v-menu(ref="bDateMenu" v-model="bDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto")
            template(v-slot:activator="{ on, attrs }")
              v-text-field(v-model="form.bDate" :label="$t('Reserve_13')" :rules="[v => !!v || $t('Reserve_13')+$t('Reserve_14')]" hide-details readonly outlined v-bind="attrs" v-on="on")
            v-date-picker(v-model="form.bDate" :active-picker.sync="activePicker" no-title locale="zh-tw" @change="saveBDate")
        v-col(cols="12" md="1")
          .d-flex.justify-center.align-center(style="height: 100%;")
            v-btn(outlined :block="$vuetify.breakpoint.smAndDown" color="blue" @click="onSearch")
              v-icon mdi-magnify
    div.mt-6.text-h5(v-if="myReserves.length>0") {{ $t('Reserve_21') }}:
    v-row.my-3 
      v-col(cols="12" lg="3" md="4" sm="6" v-for="reserve in fixReserves" :key="reserve.預掛識別碼")
        v-card.d-flex.flex-column(outlined style="height: 100%;")
          v-card-text.pb-0
            v-row
              v-col(cols="4")
                .d-flex.justify-space-between
                  span(v-for="word in $t('Reserve_3').split('')") {{ word }}
              v-col(cols="8")
                span.blue--text.font-weight-bold {{ twDateChange(reserve.日期) }}
              v-col(cols="4")
                .d-flex.justify-space-between
                  span(v-for="word in $t('Reserve_23').split('')") {{ word }}
              v-col(cols="8")
                span.blue--text.font-weight-bold {{ reserve.班別 }}
              v-col(cols="4")
                .d-flex.justify-space-between
                  span(v-for="word in $t('Reserve_24').split('')") {{ word }}
              v-col(cols="8")
                span.blue--text.font-weight-bold {{ reserve.診別 }}
              v-col(cols="4")
                .d-flex.justify-space-between
                  span(v-for="word in $t('Reserve_8').split('')") {{ word }}
              v-col(cols="8")
                span.blue--text.font-weight-bold {{ reserve.醫師名 }} {{ $t('Reserve_8') }}
              v-col(cols="4")
                .d-flex.justify-space-between
                  span(v-for="word in $t('Reserve_25').split('')") {{ word }}
              v-col(cols="8")
                span.blue--text.font-weight-bold {{ reserve.掛號序號 }}
          .flex-grow-1
          v-card-actions.px-4.pt-0.mt-2
            v-spacer
            v-btn(text color="error" :disabled="reserve.是否可退掛==='N'" @click.stop="openDialog(reserve)") {{ $t('Reserve_22') }}
          div.px-4.pb-3(v-if="reserve.是否可退掛==='N'")
            v-alert.mb-0.px-2.py-1(outlined dense type="error") {{ reserve.不可退掛原因 }}
</template>
<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";

export default {
  name: "ReserveCheck",

  data() {
    return {
      bDateMenu: false,
      form: { id: null, bDate: null },
      activePicker: null,

      myReserves: [],
    };
  },

  computed: {
    fixReserves() {
      return this.myReserves.sort((a, b) => {
        if (a.日期 < b.日期) {
          return 1;
        }
        if (a.日期 > b.日期) {
          return -1;
        }
        return 0;
      });
    },
  },

  methods: {
    ...mapActions(["GetReserves", "CancelReserve"]),
    saveBDate(date) {
      this.$refs.bDateMenu.save(date);
    },
    async onSearch() {
      if (this.form.id && this.form.bDate) {
        let result = await this.GetReserves({
          id: this.form.id,
          bDate: moment(this.form.bDate, "YYYY-MM-DD").format("YYYYMMDD"),
        });
        if (result.回傳資訊.success) {
          this.myReserves = result.預掛資訊清單;
        } else {
          // this.$swal.fire({
          //   text: result.回傳資訊.message,
          //   icon: "error",
          //   confirmButtonText: this.$t("Reserve_19"),
          // });
          this.myReserves = [];
        }
      }
    },
    twDateChange(twDate) {
      return moment(parseInt(twDate) + 19110000, "YYYYMMDD").format(
        "YYYY-MM-DD"
      );
    },
    openDialog(reserve) {
      let text = `${this.twDateChange(reserve.日期)} ${reserve.班別} ${
        reserve.診別
      } ${reserve.醫師名} ${this.$t("Reserve_8")}`;
      this.$swal.fire({
        title: this.$t("Reserve_26"),
        text: text,
        showCancelButton: true,
        confirmButtonText: this.$t("Reserve_16") + this.$t("Reserve_22"),
        cancelButtonText: this.$t("Reserve_15"),
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          const result = await this.CancelReserve({
            id: this.form.id,
            rid: reserve.預掛識別碼,
          });
          if (result.回傳資訊.success) {
            this.$swal.fire({
              title: this.$t("Reserve_22") + this.$t("Reserve_17"),
              icon: "success",
              confirmButtonText: this.$t("Reserve_19"),
            });
            await this.onSearch();
          } else {
            this.$swal.fire({
              title: `${this.$t("Reserve_22")}${this.$t("Reserve_20")}!`,
              text: result.message,
              icon: "error",
              confirmButtonText: this.$t("Reserve_19"),
            });
          }
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
    },
  },

  async mounted() {
    const { id, bDate } = this.$route.params;
    this.form.id = id;
    this.form.bDate = bDate;
    await this.onSearch();
  },

  watch: {
    bDateMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>
<style lang="sass" scoped></style>
