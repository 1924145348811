<template lang="pug">
  .mx-2(:class="{ 'mx-16': $vuetify.breakpoint.mdAndUp }")
    v-img(:src="article.img_url" eager)
    .mt-6.mx-2.text-h5 {{ article.title }}
    .mt-2.mx-4
      .d-flex.align-center.text-subtitle-1.grey--text
        v-icon(size="14") mdi-calendar-blank-outline
        span.ml-1 {{ article.post_date }}
        v-icon.ml-3(size="14") mdi-eye
        span.ml-1 {{ article.read_num }}{{ $t('News_4') }}
    .mt-6.mx-2
      .text-body-1(style="white-space: pre-line;" v-html="article.content")
    .mt-10.mb-16.mx-2
      v-chip.grey--text.text--darken-1.mr-2(v-for="tag in article.tags" :key="tag") {{ tag }}
</template>

<script>
import articles from "../../public/articles.json";

export default {
  name: "NewsInfo",

  data() {
    return {
      articles,
    };
  },

  computed: {
    article() {
      return this.articles.find(
        (article) => article.uid === this.$route.params.uid
      );
    },
  },
};
</script>
