import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.pass)?_c(VRow,[_c(VCol,{attrs:{"cols":"4","offset":"4"}},[_c(VCard,{staticClass:"mt-2",attrs:{"color":"grey lighten-5"}},[_c('form',{staticClass:"d-flex flex-column px-6 pt-8 pb-6",on:{"submit":function($event){$event.preventDefault();return _vm.verify.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"帳號","required":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.form.account),callback:function ($$v) {_vm.$set(_vm.form, "account", $$v)},expression:"form.account"}}),_c(VTextField,{staticClass:"mt-4",attrs:{"label":"密碼","required":"","outlined":"","dense":"","hide-details":"","type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c(VBtn,{staticClass:"mt-4",attrs:{"type":"submit","text":"","color":"blue"}},[_vm._v("登入")])],1)])],1)],1):[_c(VDivider),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"2"}},[_c(VList,{attrs:{"dense":"","nav":""}},_vm._l((_vm.routes),function(route){return _c(VListItem,{key:route.title,attrs:{"to":route.path,"link":"","active-class":"blue lighten-5 blue--text"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(route.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(route.title))])],1)],1)}),1)],1),_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,{attrs:{"cols":"10"}},[_c(VContainer,[_c('router-view')],1)],1)],1),_c(VDivider)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }