export default {
  App_1: "认识佑昇",
  App_2: "诊疗项目",
  App_3: "医疗团队",
  App_4: "生殖新知",
  App_5: "心路历程",
  App_6: "联系我们",
  App_7: "行动APP",
  App_8: "未经许可不得任意转载",
  App_9: "佑升诊所",
  App_10: `10491 台北市中山区长安东路二段88号8楼 
    
  电话 02-2517-6696 or 公务手机 0912-530756
  
  周一 08:00 - 22:00
  周二 08:00 - 17:00
  周三 08:00 - 22:00
  周四 08:00 - 17:00
  周五 08:00 - 17:00
  周六 08:00 - 22:00
  周日休诊`,
  App_11: "预约挂号",
  App_12: "查询预约",
  App_13: "预约相关",
  OurTeam_1: "学历",
  OurTeam_2: "经历",
  OutPatientTime_8: "上午诊 10:00-12:00 11:30 截止挂号/报到",
  OutPatientTime_11: "下午诊 13:30-17:00 16:30 截止挂号/报到",
  OutPatientTime_12: "晚上诊 18:00-21:30 21:00 截止挂号/报到",
  ReserveInfo_1: "预约专线",
  ReserveInfo_2: "10491 台北市中山区长安东路二段88号8楼",
  TrafficInfomation_1: "搭乘捷运",
  TrafficInfomation_2: "绿色松山新店线",
  TrafficInfomation_3: "黄色中和新芦线",
  TrafficInfomation_4: "松江南京站4号出口，步行约5分钟。 ",
  TrafficInfomation_5: "停车资讯",
  TrafficInfomation_6: "建国高架停车场 每小时 30元 07:00-21:30",
  TrafficInfomation_7: "长荣饭店中兴嘟嘟房 每小时 50元 24小时营业",
  TrafficInfomation_8: "门诊时间",
  TrafficInfomation_9: "周一、周三、周六 08:00-22:00",
  TrafficInfomation_10: "周二、周四、周五 08:00-17:00",
  TrafficInfomation_11: "周日休诊",
  Doctor_1: "专长",
  KnowUSoon_1: "为何选择佑升",
  KnowUSoon_2: "傲视全台之顶尖设备",
  KnowUSoon_3: `精选最新的试管婴儿设备
    优秀的胚胎师团队
    病人对成功率的满意度
    用心出色的医师群
    精致的个人化友善医疗`,
  KnowUSoon_4: `佑升诊所启于2019年6月，提供目前最新的生殖医疗科技与最尖端的医疗设备，结合国内最顶尖生殖医疗团队为您提供最好的诊疗服务。
                  
    生殖中心配备HEPA高效微粒空气过滤器，短波紫外线(紫外线C)型灯，活性炭过滤器，清除空气中传播的粒子以及外部污染物，如皮肤碎屑、纤维、毛发、细菌等。确保拥有高标准的无菌无尘实验室。
    
    我们的理念：作为生殖产业的领跑者，佑升始终坚持着诚信经营，客户至上，力求卓越的理念，为了让客户享受到全球先进优质的医疗服务，我们不断开拓，整合各方资源希望能够为每一位客户开启希望之路，致力打造优生优孕优良品牌!`,
  News_1: "--请选择--",
  News_2: "相关文章",
  News_3: "关键字搜寻",
  News_4: "人次",
  News_5: "更多",
  News_6: "依日期",
  News_7: "依浏览次数",
  News_8: "依医师",
  News_9: "依主题",
  News_10: "全部文章",
  Service_1: `让生命传承
    让幸福延续`,
  Service_2: "整合式不孕症检查与治疗",
  Service_3: "人工受孕、家庭计划",
  Service_4: "试管婴儿及冻卵相关咨询",
  Service_5: "输卵管检查及疏通",
  Service_6: "子宫体及子宫颈检查",
  Service_7: "精液检查处理及人工授精",
  Service_8: "习惯性流产的检查",
  Service_9: "着床前遗传诊断等",
  Service_10: "整合式不孕症检查与治疗",
  Service_11: "新一代试管婴儿",
  Service_12: "试管婴儿及冻卵相关咨询",
  Service_13:
    "您知道吗？平均有百分之10至15左右的已婚夫妇都有不孕症的隐忧，您并不孤单！ ",
  Service_14:
    "我需要做检测吗？ 「多子多孙多福气」，「不孝有三，无后为大」的古训，虽然已不合现代高科技及竞争经济社会的需求，但也常在「早生贵子」的婚礼祝词出现。大多数的已婚妇女仍时时受到长辈或亲友们的关爱眼神。结婚满一年，一周大约有2次以上的性生活，如果仍没有怀孕的话，则可能有不孕症的顾虑，约估已婚夫妇的百分之十至十五。一般建议年龄满30岁以上的已婚妇女，可以做一些较积极的检查。 ",
  Service_15:
    "人类的生殖仍有许多不解之谜，但是近代人工生殖医学的进步包括人工授孕及试管婴儿等治疗方式已使受孕率增加为百分之50至60。 ",
  Service_16: "排卵的预测及刺激排卵",
  Service_17: "输卵管的检查及疏通",
  Service_18: "子宫体及子宫颈检查",
  Service_19: "精液检查，处理及人工授精",
  Service_20: "着床前遗传诊断等",
  Service_21: `本院有丰富的经验及资深精英团队
    可以为您服务，欢迎莅院咨询`,
  Reserve_1: "起日",
  Reserve_2: "迄日",
  Reserve_3: "日期",
  Reserve_4: "早诊",
  Reserve_5: "午诊",
  Reserve_6: "晚诊",
  Reserve_7: "代诊",
  Reserve_8: "医师",
  Reserve_9: "预约",
  Reserve_10: "进行预约挂号",
  Reserve_11: "请输入身份证号及生日",
  Reserve_12: "身份证号*",
  Reserve_13: "生日*",
  Reserve_14: "必填",
  Reserve_15: "取消",
  Reserve_16: "确认",
  Reserve_17: "成功",
  Reserve_18: "您的看诊号为",
  Reserve_19: "关闭",
  Reserve_20: "失败",
  Reserve_21: "您的预约资料如下",
  Reserve_22: "退挂",
  Reserve_23: "班別",
  Reserve_24: "诊别",
  Reserve_25: "看诊号",
  Reserve_26: "请确认是否将此次预约进行退挂",
  Reserve_27: "初诊请输入姓名及电话",
  Reserve_28: "姓名*",
  Reserve_29: "电话*",
  Reserve_30: "挂号",
  Reserve_31: "查询",
};
